import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import store from 'store/store'
import { calculateTimeDiff } from 'shared/utils/calculateTimeDiff/calculateTimeDiff'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { useNavigate } from 'react-router-dom'
import {
  addCompletedSurvey,
  updateContinueSurveyResponse,
  updateContinueLocalIdentifier,
  updateUserJourney
} from 'store/reducer'
import { InnerModalProps, SurveyStatusState } from './InnerModal.model'
import { IconType } from 'components/Icons/Icon.model'
import { Icon } from 'components/Icons/Icon.component'
import styles from './InnerModal.module.scss'

export const InnerModal: React.FC<InnerModalProps> = ({
  modalState = '',
  modalClose,
  modalCloseParent
}) => {
  const dispatch: Dispatch<any> = useDispatch()
  const [isReasonStep, setIsReasonStep] = useState(false)
  const [reason, setReason] = useState('')
  const requiresStep = ['deletion', 'technical'].includes(modalState)
  const navigate = useNavigate()

  useEffect(() => {
    setIsReasonStep(false)
    setReason('')
  }, [modalState])

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReason(event.target.value)
  }

  const handleConfirm = () => {
    if (isReasonStep) {
      handleSubmit(modalState, dispatch, reason)
      modalCloseParent()
      navigate('/ra-dashboard/surveys')
    } else if (requiresStep) {
      setIsReasonStep(true)
    } else {
      handleSubmit(modalState, dispatch)
      modalClose()
      modalCloseParent()
      navigate('/ra-dashboard/surveys')
    }
  }

  const handleClose = () => {
    modalClose()
    setIsReasonStep(false)
  }

  const questionText =
    modalState === 'deletion'
      ? 'Reason for leaving the study'
      : 'What was the technical problem?'

  return (
    <Modal
      open={!!modalState}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={styles['inner-modal']}
    >
      <div className={styles['modal-container']}>
        <div className={styles['modal-container-content']}>
          {isReasonStep && <span>{questionText}</span>}
          {!isReasonStep ? (
            <>
              <div className={styles['icon-text-container']}>
                <Icon
                  icon={
                    modalState === 'deletion'
                      ? IconType.Delete
                      : modalState === 'technical'
                      ? IconType.Technical
                      : modalState === 'incomplete'
                      ? IconType.Incomplete
                      : IconType.Stop
                  }
                />
                <span>
                  Do you really want to flag this survey as &apos;{modalState}
                  &apos;?
                </span>
              </div>
            </>
          ) : (
            <textarea
              className={styles['textarea']}
              value={reason}
              onChange={handleChange}
              placeholder="Enter reason..."
            />
          )}

          <div className={styles['modal-container-button']}>
            <button className={styles['confirm']} onClick={handleConfirm}>
              {isReasonStep ? 'Confirm' : 'Yes'}
            </button>
            <button className={styles['cancel']} onClick={handleClose}>
              {isReasonStep ? 'Cancel' : 'No'}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const updateSubmissionMetadata = (status: SurveyStatusState, reason = '') => {
  const globalState = store.getState()
  const endTime = new Date()

  const startTime = globalState.surveyMetaData?.startTime || new Date()
  const resumeTime = globalState.surveyMetaData?.resumeTime
  const duration =
    (calculateTimeDiff(resumeTime || startTime, endTime) as number) +
    (globalState.surveyMetaData?.duration || 0)

  // Any survey response being submitted from the RA inner modal is incomplete at that moment.
  return {
    ...globalState.surveyMetaData,
    status,
    flaggedIncomplete: true,
    flaggedComingBack: status === 'incomplete',
    flaggedDeleted: status === 'deletion',
    flaggedTechnical: status === 'technical',
    deletionReason: status === 'deletion' ? reason : undefined,
    technicalReason: status === 'technical' ? reason : undefined,
    endTime,
    duration
  }
}

export const ExitDashboardConfirm = ({
  modalState,
  modalClose,
  modalCloseParent
}: InnerModalProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const navigate = useNavigate()
  const handleExitToDashboard = () => {
    handleSubmit(modalState, dispatch)
    modalCloseParent()

    navigate('/ra-dashboard/dashboard')
  }

  return (
    <div className={styles['inner-modal-exit']}>
      <div className={styles['inner-modal-exit-content']}>
        <span className={styles['inner-modal-exit-warning']}>
          Warning:&nbsp;
        </span>
        If you exit to the dashboard, then this survey will be paused.
        <br />
        This survey will be flagged as <b>Incomplete</b>
        <br />
        You will follow to RA-Dashboard. Continue?
      </div>
      <div className={styles['inner-modal-exit-button-container']}>
        <button
          className={styles['inner-modal-exit-button-yes']}
          onClick={handleExitToDashboard}
        >
          Yes
        </button>
        <button
          className={styles['inner-modal-exit-button-cancel']}
          onClick={modalCloseParent}
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

const handleSubmit = (
  status: SurveyStatusState,
  dispatch: Dispatch<any>,
  reason = ''
) => {
  //console.log(status)
  const metadata = updateSubmissionMetadata(status, reason)
  const globalState = store.getState()
  const continueLocalIdentifier = globalState.continueLocalIdentifier

  // Update screen path to empty on submit
  dispatch(updateUserJourney(undefined))

  if (!continueLocalIdentifier) {
    dispatch(
      addCompletedSurvey({
        save: true,
        responses: globalState.surveyData,
        metadata,
        userJourney:
          status === 'incomplete' ? globalState.userJourney : undefined,
        grogDiaryUserJourney:
          status === 'incomplete'
            ? globalState.grogDiaryUserJourney
            : undefined,
        grogDiaryCurrentState:
          status === 'incomplete'
            ? globalState.grogDiaryCurrentState
            : undefined,
        analytics: globalState.analytics,
        drinkingSessions: globalState.drinkingSessions
      })
    )
  } else {
    dispatch(updateContinueLocalIdentifier(continueLocalIdentifier))
    dispatch(
      updateContinueSurveyResponse({
        responses: globalState.surveyData,
        metadata,
        userJourney: globalState.userJourney,
        grogDiaryUserJourney: globalState.grogDiaryUserJourney,
        grogDiaryCurrentState: globalState.grogDiaryCurrentState,
        analytics: globalState.analytics,
        drinkingSessions: globalState.drinkingSessions
      })
    )
  }
}
