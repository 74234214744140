import React, { useContext, useEffect, useState } from 'react'
import {
  GrogDiaryData,
  GrogDiaryDataDetail,
  IDrinkingSessionConsumption,
  ISurveyDrinkingSessions,
  SurveyState
} from 'store/type'
import {
  GrogDiaryScreenAudioData,
  GrogDiaryScreenLastDayAudioKey,
  GrogDiaryScreenPropsModel,
  GrogDiaryScreenStatePropsModel,
  GrogDiaryScreenTypesCategoriesAudioKey,
  GrogDiaryStep
} from './GrogDiaryScreen.model'
import { connect, useDispatch } from 'react-redux'
import { Screen } from 'components/Screen/Screen.component'
import styles from './GrogDiaryScreen.module.scss'
import { CalendarMultiSelect } from 'components/GrogConsumptions/CalendarMultiSelect/CalendarMultiSelect.component'
import { CalendarEvent } from 'components/GrogConsumptions/CalendarMultiSelect/CalendarMultiSelect.model'
import { MultipleChoice } from 'components/MultipleChoice/MultipleChoice.component'
import { MultipleChoiceModel } from 'components/MultipleChoice/MultipleChoice.model'
import {
  GrogShopDataModel,
  GrogSubTypeModel,
  GrogTypeCategoryModel
} from 'api/client.model'
import { GrogSlider } from 'components/GrogConsumptions/GrogSlider/GrogSlider.component'
import { Container } from 'components/GrogConsumptions/Container/Container.component'
import { Dispatch } from 'redux'
import {
  updateSurveyAnswer,
  updateDrinkingSession,
  updateGrogDiaryUserJourney,
  updateGrogDiaryCurrentConsumption
} from 'store/reducer'
import { AddMultiDate } from 'components/GrogConsumptions/AddMultiDate/AddMultiDate.component'
import { GrogDiary } from 'components/GrogConsumptions/GrogDiary/GrogDiary.component'
import { VoiceOverTypes } from 'components/Header/Header.model'
import { genericObject } from '../type'
import { DrinkingCircleSlider } from 'components/GrogConsumptions/DrinkingCircleSlider/DrinkingCircleSlider.component'
import {
  AlcoholCalc,
  calcMaxDisplayContainers,
  calcNumStepsPerProductImage
} from 'shared/utils/alcoholCalculation/alcoholCalculation'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import { AudioContext } from 'context/AudioContext'
import { GrogConsumptionTransitionStep } from 'shared/utils/grogConsumptionState/grogConsumptionState.model'
import { useGrogConsumptionState } from 'shared/utils/grogConsumptionState/grogConsumptionState'
import * as consumptionStateUtils from 'shared/utils/grogConsumptionState/utils'
import type { DeepReadonly } from 'shared/types/general'
import { assertNonNull, typeCategoryHasSubTypes } from 'shared/types/guards'
import {
  GROG_DIARY_CURRENT_STATE_DEFAULT,
  GROG_DIARY_GROUP_PEOPLE_INDEX_RANGE,
  GROG_DIARY_MORE_THEN_12_MONTHS_AGO
} from 'shared/constants/Constants.d'

export const GrogDiaryScreenComponent: React.FC<
  GrogDiaryScreenPropsModel & GrogDiaryScreenStatePropsModel
> = (props) => {
  const dispatch: Dispatch<any> = useDispatch()

  const {
    currentScreenId,
    name,
    headerProps,
    screenData: {
      requireDiary,
      otherDayDiary,
      groupStdDrinksThreshold,
      peopleImages,
      justMeImage,
      groupImage
    },
    footerProps,
    surveyData,
    events,
    birthday,
    voiceOver,
    typesCategories,
    grogDiaryUserJourney,
    grogDiaryCurrentState
  } = props

  // Object to represent audio fragments of screen
  const audioFragments: GrogDiaryScreenAudioData = props.screenData

  const { playAudio, setScreenAudioKey } = useContext(AudioContext)
  const { getNextScreenId } = matchCondition()

  // Current Active Step
  const [step, setStep] = useState<GrogDiaryStep>(
    grogDiaryUserJourney.at(-1)?.step ?? GrogDiaryStep.LASTDAY
  )

  const stepForward = (value: GrogDiaryStep) => {
    const stepForwardSurveyData = {
      step: value,
      skipped: false
    }

    dispatch(
      updateGrogDiaryUserJourney({
        actionType: 'push',
        data: stepForwardSurveyData
      })
    )
    setStep(value)
  }

  const stepBackward = () => {
    const previousStep = grogDiaryUserJourney.at(-2)
    if (previousStep == null) {
      return
    }

    dispatch(updateGrogDiaryUserJourney({ actionType: 'pop' }))

    // If step was marked as "skip", clear the skip data
    if (previousStep.skipped) {
      dispatch(updateGrogDiaryUserJourney({ actionType: 'clear-skipped' }))
    }

    setStep(previousStep.step)
  }

  // Since date must be stored in (serialised) form in Redux as a string, we need to parse
  // this string and convert it to a JavaScript `Date` object
  const date = grogDiaryCurrentState.consumption?.date
    ? new Date(grogDiaryCurrentState.consumption?.date)
    : undefined
  const setDate = (value: DeepReadonly<Date> | undefined) => {
    dispatch(
      updateGrogDiaryCurrentConsumption({
        actionType: 'setValue',
        key: 'date',
        value: value?.toISOString()
      })
    )
  }

  const people = grogDiaryCurrentState.consumption?.people
  const setPeople = (value: number | undefined) => {
    dispatch(
      updateGrogDiaryCurrentConsumption({
        actionType: 'setValue',
        key: 'people',
        value
      })
    )
  }

  const [moreThan12Months, setMoreThan12Months] = useState<boolean>(false)

  let audioFragmentsKey: string | null = null
  let drinkingSessions: ISurveyDrinkingSessions[] | null = null

  useEffect(() => {
    if (audioFragmentsKey) {
      setScreenAudioKey?.(`${currentScreenId}-${audioFragmentsKey}`)
      playAudio?.(`${currentScreenId}-${audioFragmentsKey}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, audioFragmentsKey])

  // GitHub issue #167
  useEffect(() => {
    if (drinkingSessions != null) {
      dispatch(updateDrinkingSession(drinkingSessions))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drinkingSessions])

  const diary =
    surveyData !== undefined && surveyData[name]
      ? typeof surveyData[name] == 'string'
        ? (JSON.parse(surveyData[name]) as { [key: string]: GrogDiaryData })
        : (surveyData[name] as { [key: string]: GrogDiaryData })
      : undefined

  const grogConsumptionState = useGrogConsumptionState({
    name,
    groupStdDrinksThreshold,
    typesCategories,
    grogDiaryUserJourney,
    grogDiaryCurrentState,
    date: { value: date, set: setDate },
    people: { value: people, set: setPeople },
    step: { value: step, set: setStep, stepForward, stepBackward },
    diary
  })

  const currentConsumptionState = grogConsumptionState.current
  const currentConsumptionSelections = currentConsumptionState.selections

  const selectedDates = consumptionStateUtils.getDateStringsFromDiary(diary)

  /**
   * Get previous day based on the input date object
   * @param d Input date object
   * @returns Previous date object
   */
  const getPrevDate = (d: Date) => {
    return new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1)
  }

  const maxDate = getPrevDate(new Date())
  const minDate = new Date(
    maxDate.getFullYear() - 1,
    maxDate.getMonth(),
    maxDate.getDate()
  )

  const eventList: CalendarEvent[] = events.map((e) => {
    const dateParts = e.date.split('/')
    return {
      ...e,
      date: new Date(+dateParts[2], Number(dateParts[1]) - 1, +dateParts[0])
    } as CalendarEvent
  })

  const selectedDateList =
    consumptionStateUtils.getCalendarEventsFromDiary(diary)

  const getMaxDate = (): Date => {
    if (
      selectedDates &&
      selectedDates.length > 0 &&
      selectedDates.length < requireDiary + otherDayDiary
    ) {
      return getPrevDate(new Date(selectedDates[selectedDates.length - 1]))
    }
    return maxDate
  }

  /**
   * Get the key to fetch the correct audio fragments when the Grog Diary is on the
   * {@link GrogDiaryStep.LASTDAY} step, depending on how many dates have already been
   * selected.
   *
   * @returns The string key to fetch the correct audio fragments for the Grog Diary on
   * the {@link GrogDiaryStep.LASTDAY} step, depending on the current state of the diary.
   */
  const getLastDayAudioFragmentsKey = (): GrogDiaryScreenLastDayAudioKey => {
    switch (selectedDateList.length) {
      case 0:
        // If this is the first time the user is visiting this screen
        return 'lastDay'
      case 1:
        // If this is the 2nd time the user is visiting this screen
        return 'whenWasTheTimeBefore'
      case requireDiary:
        // If the use is visiting the calendar screen to input their first "non-detailed"
        // consumption date of the Grog Diary
        return 'firstNonDetailedDate'
      default:
        // For all other future times the user visits this screen
        return 'timeBeforeThat'
    }
  }

  /**
   * Get the key to fetch the correct audio fragments when the Grog Diary is on the
   * {@link GrogDiaryStep.TYPESCATEGORIES} step, depending on how many consumptions exist
   * for the current date. If the user has at least 1 consumption on the same date,
   * `'whatElseDidYouDrinkThatDay'` (and, implicitly, the current date and diary are not
   * `undefined`) will be returned, otherwise, `'lastDayType'` will be returned.
   *
   * @returns The string key to fetch the correct audio fragments for the Grog Diary on
   * the {@link GrogDiaryStep.TYPESCATEGORIES} step, depending on the current state of the
   * diary.
   */
  const getTypesCategoriesAudioFragmentsKey =
    (): GrogDiaryScreenTypesCategoriesAudioKey | null => {
      if (date == null || diary == null) {
        // No date OR diary defined, so we can't check any consumption data
        // Return the default audio clip ('lastDayType')
        return 'lastDayType'
      }

      const currentDateDiaryData = diary[date.toISOString()] as
        | GrogDiaryData
        | undefined
      if (currentDateDiaryData == null) {
        // No consumptions defined for the current date
        return 'lastDayType'
      }

      if (currentDateDiaryData.consumptions.length >= 1) {
        // At least 1 consumption on the same date
        return 'whatElseDidYouDrinkThatDay'
      } else {
        // First consumption for this date
        return 'lastDayType'
      }
    }

  /**
   * Check whether the user has entered all required dates for the Grog Diary.
   *
   * @returns `true` if the user entered at least the same amount of dates as
   * `requireDiary` + `otherDayDiary`, `false` otherwise.
   */
  const userCompletedAllDates = () =>
    selectedDateList.length >= requireDiary + otherDayDiary

  /**
   * Attempt to get the audio fragments based on the current selection state of the Grog
   * Diary, and a key qualifier. Audio fragments will attempt to be fetched from the most
   * specific selection (i.e. alcohol sub-type) first.
   *
   * @param key - The key that is to be used to find matching audio fragments from an
   * alcohol selection.
   *
   * @returns The audio fragments found in the alcohol selection for the current `key`, or
   * `null` if none exist.
   */
  const getCurrentAudioFragments = (
    key: string
  ): DeepReadonly<genericObject> | null => {
    // Try from the most specific, to least specific selection
    let audioFragmentsSelection = undefined

    if (currentConsumptionState.subType.value?.audioFragments) {
      audioFragmentsSelection =
        currentConsumptionState.subType.value.audioFragments
    } else if (currentConsumptionState.typeCat.value?.audioFragments) {
      audioFragmentsSelection =
        currentConsumptionState.typeCat.value.audioFragments
    }

    // If there is no valid audio fragment selection, simply return
    if (audioFragmentsSelection == null) {
      return null
    }

    // Try to find the audio fragments matching the provided key
    const foundAudioFragments = audioFragmentsSelection.find(
      (fragment) => fragment.name === key
    )?.voices
    return foundAudioFragments ?? null
  }

  const handleHeaderProp = () => {
    const updatedHeaderProps = { ...headerProps }
    let headerAudioFragments: genericObject | null = null

    switch (step) {
      case GrogDiaryStep.LASTDAY:
        // Get key of audio for date, and use this to access the audio fragments
        headerAudioFragments =
          audioFragments[getLastDayAudioFragmentsKey()] ?? null
        break

      case GrogDiaryStep.PEOPLE:
        headerAudioFragments = audioFragments.howManyPeople ?? null
        break

      case GrogDiaryStep.TYPESCATEGORIES: {
        const headerAudioFragmentsKey = getTypesCategoriesAudioFragmentsKey()
        if (headerAudioFragmentsKey != null) {
          // Defined - set to the correct audio fragment
          headerAudioFragments = audioFragments[headerAudioFragmentsKey] ?? null
        }

        break
      }

      case GrogDiaryStep.SUBTYPE:
      case GrogDiaryStep.BRAND:
        headerAudioFragments = getCurrentAudioFragments('what-types')
        break

      case GrogDiaryStep.PRODUCT:
        headerAudioFragments = audioFragments.whatDidYouDrinkOutOf ?? null
        break

      case GrogDiaryStep.PRODUCT_CONTAINER:
        headerAudioFragments = getCurrentAudioFragments('how-much')
        break

      case GrogDiaryStep.CUSTOM_CONTAINER:
        headerAudioFragments = audioFragments.howMuchPutInIt ?? null
        break

      case GrogDiaryStep.IS_GROUP:
        headerAudioFragments = audioFragments.meOrGroup ?? null
        break

      case GrogDiaryStep.SHARE:
        headerAudioFragments = audioFragments.share ?? null
        break

      case GrogDiaryStep.MORE:
        headerAudioFragments = audioFragments.anythingMoreToDrink ?? null
        break

      case GrogDiaryStep.OTHERDATE:
        if (!userCompletedAllDates() && audioFragments.timeBeforeThat) {
          headerAudioFragments = audioFragments.timeBeforeThat
        } else {
          headerAudioFragments = null
        }

        break

      case GrogDiaryStep.DIARY:
        headerAudioFragments = audioFragments.allYouHadToDrink ?? null
        break

      default:
        break
    }

    if (headerAudioFragments != null) {
      updatedHeaderProps.voiceOver = voiceOver?.filter((v) =>
        Object.keys(headerAudioFragments as genericObject).includes(v.value)
      ) as VoiceOverTypes[]
    }

    return updatedHeaderProps
  }

  const { calcStandardDrinks } = AlcoholCalc()

  /**
   * Step forward in the Grog Diary when on the diary summary screen for a specific date.
   */
  const summaryScreenStepForward = () => {
    grogConsumptionState.resetState(true)

    let nextStep: GrogDiaryStep
    if (otherDayDiary < 1) {
      // Edge case: if the "other day diary" value is set to 0, just go to the
      // calendar summary screen
      nextStep = GrogDiaryStep.OTHERDATE
    } else {
      // Normal case: the user should select other dates for the "other day diary"
      nextStep = GrogDiaryStep.LASTDAY
    }

    stepForward(nextStep)
  }

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }

    updatedFooterProps.invalid = true
    let lastScreen = false

    switch (step) {
      case GrogDiaryStep.LASTDAY: {
        if (moreThan12Months) {
          /*
            If "More than 12 months ago" is selected, at this step
            1. A flag "moreThan12MonthsAgo" will be passed to getNextScreenId
              func to return the nextScreenId based on condition check;
            2. The toggleNextButton with true value will trigger an onNextClick
              event - the rest of the alcohol section will be skipped(GitHub #31)
           */
          updatedFooterProps.nextScreenId = getNextScreenId(
            GROG_DIARY_MORE_THEN_12_MONTHS_AGO,
            footerProps.conditions,
            footerProps.nextScreenId
          )
          updatedFooterProps.toggleNextButton = true
          break
        }

        if (date || selectedDateList.length === requireDiary + otherDayDiary) {
          updatedFooterProps.invalid = false
        }

        audioFragmentsKey = getLastDayAudioFragmentsKey()

        if (selectedDateList.length > 0) {
          // Set up the back button so that it navigates back to either the diary summary
          // screen (summary of everything consumed on specific date), or the "select
          // date" screen.
          updatedFooterProps.customBackClick = () => {
            if (selectedDateList.length <= requireDiary) {
              // Must be a date defined, as this custom back action only shows if
              // `selectedDateList.length` > 1
              const lastEvent = selectedDateList.at(-1)!
              setDate(lastEvent.date)
              grogConsumptionState.popFromDiary()
            }

            stepBackward()
          }
        }

        updatedFooterProps.customNextClick = () => {
          if (date == null) {
            // This should not happen - throw an error
            throw new Error(
              '`date` should not be nullish when selection is active in calendar ' +
                'selection screen'
            )
          }

          let nextStep

          if (selectedDateList.length <= requireDiary - 1) {
            if (selectedDateList.length === 0) {
              nextStep = GrogDiaryStep.PEOPLE
            } else {
              nextStep = GrogDiaryStep.TYPESCATEGORIES
            }
          } else {
            // Add date-only consumption to diary
            dispatch(
              updateSurveyAnswer({
                [name]: {
                  ...diary,
                  [date.toISOString()]: {
                    consumptions: []
                  }
                }
              })
            )

            nextStep = GrogDiaryStep.OTHERDATE
          }

          stepForward(nextStep)
        }
        break
      }

      case GrogDiaryStep.PEOPLE: {
        if (people != null && people !== 0) {
          updatedFooterProps.invalid = false
        }

        audioFragmentsKey = 'howManyPeople'

        updatedFooterProps.customNextClick = () => {
          stepForward(GrogDiaryStep.TYPESCATEGORIES)
        }
        updatedFooterProps.customBackClick = () => {
          grogConsumptionState.goBackToPreviousConsumptionScreen()
        }
        break
      }

      case GrogDiaryStep.TYPESCATEGORIES: {
        if (currentConsumptionSelections.activeTypesCats.value.length > 0) {
          updatedFooterProps.invalid = false
        }

        audioFragmentsKey = getTypesCategoriesAudioFragmentsKey()

        updatedFooterProps.customNextClick = () => {
          grogConsumptionState.transition(GrogConsumptionTransitionStep.NEXT)
        }
        updatedFooterProps.customBackClick = () => {
          grogConsumptionState.transition(GrogConsumptionTransitionStep.BACK)
        }
        break
      }

      case GrogDiaryStep.SUBTYPE: {
        if (currentConsumptionSelections.activeSubTypes.value.length > 0) {
          updatedFooterProps.invalid = false
        }

        audioFragmentsKey = currentConsumptionState.audioFragmentsKeys.whatTypes

        updatedFooterProps.customNextClick = () => {
          grogConsumptionState.transition(GrogConsumptionTransitionStep.NEXT)
        }
        updatedFooterProps.customBackClick = () => {
          grogConsumptionState.transition(GrogConsumptionTransitionStep.BACK)
        }
        break
      }

      case GrogDiaryStep.BRAND: {
        if (currentConsumptionSelections.activeBrands.value.length > 0) {
          updatedFooterProps.invalid = false
        }

        audioFragmentsKey = currentConsumptionState.audioFragmentsKeys.whatTypes

        updatedFooterProps.customNextClick = () => {
          grogConsumptionState.transition(GrogConsumptionTransitionStep.NEXT)
        }

        updatedFooterProps.customBackClick = () => {
          grogConsumptionState.transition(GrogConsumptionTransitionStep.BACK)
        }

        break
      }

      case GrogDiaryStep.PRODUCT: {
        if (
          currentConsumptionState.container.value ||
          currentConsumptionState.product.value
        ) {
          updatedFooterProps.invalid = false
        }

        audioFragmentsKey = 'whatDidYouDrinkOutOf'

        updatedFooterProps.customNextClick = () => {
          grogConsumptionState.transition(GrogConsumptionTransitionStep.NEXT)
        }

        updatedFooterProps.customBackClick = () => {
          grogConsumptionState.transition(GrogConsumptionTransitionStep.BACK)
        }

        break
      }

      case GrogDiaryStep.PRODUCT_CONTAINER: {
        if (
          currentConsumptionState.drinkAmount.value != null &&
          currentConsumptionState.drinkAmount.value !== 0
        ) {
          updatedFooterProps.invalid = false
        }

        audioFragmentsKey = currentConsumptionState.audioFragmentsKeys.howMuch

        updatedFooterProps.customNextClick = () => {
          grogConsumptionState.transition(GrogConsumptionTransitionStep.NEXT)
        }

        updatedFooterProps.customBackClick = () => {
          grogConsumptionState.transition(GrogConsumptionTransitionStep.BACK)
        }

        break
      }

      case GrogDiaryStep.CUSTOM_CONTAINER: {
        if (currentConsumptionState.containerAmount.value) {
          updatedFooterProps.invalid = false
        }

        audioFragmentsKey = 'howMuchPutInIt'

        updatedFooterProps.customNextClick = () => {
          grogConsumptionState.transition(GrogConsumptionTransitionStep.NEXT)
        }

        updatedFooterProps.customBackClick = () => {
          grogConsumptionState.transition(GrogConsumptionTransitionStep.BACK)
        }

        break
      }

      case GrogDiaryStep.IS_GROUP: {
        if (currentConsumptionState.isGroup.value != null) {
          updatedFooterProps.invalid = false
        }

        audioFragmentsKey = 'meOrGroup'

        updatedFooterProps.customNextClick = () => {
          grogConsumptionState.transition(GrogConsumptionTransitionStep.NEXT)
        }

        updatedFooterProps.customBackClick = () => {
          grogConsumptionState.transition(GrogConsumptionTransitionStep.BACK)
        }

        break
      }

      case GrogDiaryStep.SHARE: {
        if (
          currentConsumptionState.share.value != null &&
          currentConsumptionState.share.value !== 0
        ) {
          updatedFooterProps.invalid = false
        }

        audioFragmentsKey = 'share'

        updatedFooterProps.customNextClick = () => {
          grogConsumptionState.transition(GrogConsumptionTransitionStep.NEXT)
        }

        updatedFooterProps.customBackClick = () => {
          grogConsumptionState.transition(GrogConsumptionTransitionStep.BACK)
        }

        break
      }

      case GrogDiaryStep.MORE: {
        if (currentConsumptionState.more.value != null) {
          updatedFooterProps.invalid = false
        }

        audioFragmentsKey = 'anythingMoreToDrink'

        updatedFooterProps.customNextClick = () => {
          grogConsumptionState.transition(GrogConsumptionTransitionStep.NEXT)
        }

        updatedFooterProps.customBackClick = () => {
          grogConsumptionState.transition(GrogConsumptionTransitionStep.BACK)
        }

        break
      }

      case GrogDiaryStep.DIARY: {
        // disable the next button when item's drinkAmount in consumptions list is 0
        if (date && diary && diary[date.toISOString()]) {
          updatedFooterProps.invalid =
            diary[date.toISOString()].consumptions?.filter((item) =>
              Boolean(item?.drinkAmounts.individualAmount)
            ).length === 0
        }

        audioFragmentsKey = 'allYouHadToDrink'

        updatedFooterProps.customNextClick = summaryScreenStepForward

        updatedFooterProps.customBackClick = () => {
          grogConsumptionState.goBackToPreviousConsumptionScreen()
        }

        break
      }

      case GrogDiaryStep.OTHERDATE: {
        if (
          (diary &&
            Object.keys(diary).length === requireDiary + otherDayDiary) ||
          moreThan12Months
        ) {
          updatedFooterProps.invalid = false

          // Next Screen Condition Logic
          updatedFooterProps.nextScreenId = getNextScreenId(
            undefined,
            footerProps.conditions,
            footerProps.nextScreenId
          )

          lastScreen = true
        }

        if (!userCompletedAllDates()) {
          audioFragmentsKey = 'timeBeforeThat'
        }

        if (selectedDateList.length > 0) {
          updatedFooterProps.customBackClick = () => {
            const lastEvent = selectedDateList.at(-1)!
            setDate(lastEvent.date)

            if (otherDayDiary <= 0 && selectedDateList.length <= requireDiary) {
              // In the edge case that `otherDayDiary == 0`, and the user has selected all
              // their require dates (thus moving to this screen as a "summary" of the
              // dates they have selected), popping will ensure the Grog Diary state can
              // be restored and modified if the user wishes to go back
              grogConsumptionState.popFromDiary(true)
            } else {
              // In this case, the user will have selected at *least* 1 of the
              // `otherDayDiary` dates. Because this is therefore a "date-only"
              // consumption, we delete the date
              grogConsumptionState.deleteConsumption()
            }

            stepBackward()
          }
        }

        break
      }

      default:
        break
    }

    // Update drinking sessions data model with grog diary on screen change to catch all
    // dates
    if (diary) {
      drinkingSessions = Object.keys(diary).map(
        (key): ISurveyDrinkingSessions => {
          // Sum all alcohol in drinking session
          let gramsAlcohol = 0

          const consumptions = diary[key]?.consumptions ?? []

          const transformedConsumptions = consumptions?.map(
            (consumption: GrogDiaryDataDetail): IDrinkingSessionConsumption => {
              // Calc grams alcohol for each consumption by multiplying standard drinks by
              // 10 and add to total for drinking session
              const consumptionGramsAlcohol =
                calcStandardDrinks(consumption) * 10
              gramsAlcohol += consumptionGramsAlcohol

              return {
                category: consumption?.typecategory?.name ?? null,
                subcategory: consumption?.subtype?.name ?? null,
                unitsPerItem: consumption?.subContainer?.amount ?? null,
                units: consumption?.drinkAmounts.individualAmount ?? null,
                fullness: consumption?.container.grog ?? null,
                mixer: consumption?.container.fizzy ?? null,
                productCode: consumption?.uuid ?? null,
                brand: consumption?.brand ?? null,
                container: consumption?.container.name ?? null,
                displayText: consumption?.product?.name ?? null,
                gramsAlcohol: consumptionGramsAlcohol ?? null
              }
            }
          )

          return {
            drinkingDate: key,
            gramsAlcohol: gramsAlcohol ?? null,
            consumption: transformedConsumptions
          }
        }
      )
    }

    return updatedFooterProps
  }

  const onDiaryIncrease = (uuid: string) => {
    if (date && diary) {
      dispatch(
        updateSurveyAnswer({
          [name]: {
            ...diary,
            [date?.toISOString()]: {
              ...diary[date.toISOString()],
              consumptions: diary[date.toISOString()].consumptions.map(
                (consumption) => {
                  if (consumption.uuid === uuid) {
                    const adjustedConsumption = structuredClone(consumption)

                    if (
                      adjustedConsumption.drinkAmounts.individualAmount !=
                        null &&
                      adjustedConsumption.drinkAmounts.individualAmount !== 0
                    ) {
                      adjustedConsumption.drinkAmounts.individualAmount += 1
                    } else {
                      adjustedConsumption.drinkAmounts.individualAmount = 1
                    }

                    return adjustedConsumption
                  }

                  return consumption
                }
              )
            }
          }
        })
      )
    }
  }

  const onDiaryDecrease = (uuid: string) => {
    if (date && diary) {
      dispatch(
        updateSurveyAnswer({
          [name]: {
            ...diary,
            [date?.toISOString()]: {
              ...diary[date.toISOString()],
              consumptions: diary[date.toISOString()].consumptions.map(
                (consumption) => {
                  if (consumption.uuid === uuid) {
                    const adjustedConsumption = structuredClone(consumption)

                    if (
                      adjustedConsumption.drinkAmounts.individualAmount !=
                        null &&
                      adjustedConsumption.drinkAmounts.individualAmount !== 0
                    ) {
                      adjustedConsumption.drinkAmounts.individualAmount -= 1
                    } else {
                      adjustedConsumption.drinkAmounts.individualAmount = 0
                    }

                    return adjustedConsumption
                  }

                  return consumption
                }
              )
            }
          }
        })
      )
    }
  }

  /**
   * Render the string to be displayed as the page title for when the user has to input
   * the first "other" (non-detailed) date of the Grog Diary, which will describe the
   * number of detailed consumption dates the user has inputted already, and the number of
   * remaining non-detailed dates the user has yet to enter.
   *
   * @returns The string to be displayed on the page title of the Grog Diary for when the
   * user has to input the first non-detailed date of a drinking occasion in the Grog
   * Diary.
   */
  const constructFirstOtherDateInputTitle = (): string => {
    return (
      `Thanks for telling me about the last ${requireDiary} times you had ` +
      `a drink. Can you show me the last ${otherDayDiary} days before that when ` +
      'you had a drink?'
    )
  }

  /**
   * Render component based on current step
   */
  const renderStep = () => {
    const curTypeCat = currentConsumptionState.typeCat
    const curSubType = currentConsumptionState.subType
    const curBrand = currentConsumptionState.brand
    const curProduct = currentConsumptionState.product
    const curContainer = currentConsumptionState.container
    const curDrinkAmount = currentConsumptionState.drinkAmount
    const curContainerAmount = currentConsumptionState.containerAmount

    let typeName
    if (curBrand.value) {
      typeName = curBrand.value.name
    } else if (curSubType.value) {
      typeName = curSubType.value.name.toLowerCase()
    } else {
      typeName = curTypeCat.value?.name?.toLowerCase()
    }

    switch (step) {
      case GrogDiaryStep.LASTDAY: {
        // Text shown will depend on number of dates recorded so far
        let title

        switch (selectedDateList.length) {
          case 0:
            // If this is the first time the user is visiting this screen
            title = 'When was the last day you had any grog?'
            break
          case 1:
            // If this is the 2nd time the user is visiting this screen
            title =
              'Thanks. Before that, when was the last day you had any grog? Can you ' +
              'show me when it was?'
            break
          case requireDiary:
            // If the use is visiting the calendar screen to input their first
            // "non-detailed" consumption date of the Grog Diary
            title = constructFirstOtherDateInputTitle()
            break
          default:
            // For all other future times the user visits this screen
            title = 'What about the time before that?'
            break
        }

        return (
          <CalendarMultiSelect
            title={title}
            notice={
              selectedDates.length < requireDiary + otherDayDiary
                ? 'please select the last date when you had a drink'
                : `You've selected all ${selectedDates.length} dates`
            }
            selectRange={false}
            setValue={setDate}
            maxDate={getMaxDate()}
            minDate={minDate}
            events={[...eventList, ...selectedDateList]}
            birthday={birthday}
            isMoreThan12MonthsShow={
              selectedDates.length >= 1 &&
              selectedDates.length < requireDiary + otherDayDiary
                ? true
                : false
            }
            moreThan12MonthsEvent={() => {
              setMoreThan12Months(true)
            }}
            selectionDisabled={
              selectedDates.length === requireDiary + otherDayDiary
            }
          />
        )
      }

      case GrogDiaryStep.PEOPLE:
        return (
          <DrinkingCircleSlider
            title="How many people were drinking with you, that last time?"
            subtitle="(drag the number of people into your drinking circle)"
            images={peopleImages}
            min={GROG_DIARY_GROUP_PEOPLE_INDEX_RANGE[0]}
            max={GROG_DIARY_GROUP_PEOPLE_INDEX_RANGE.at(-1)}
            defaultValue={0}
            setValue={(v) => {
              setPeople(v as number)
            }}
          />
        )

      case GrogDiaryStep.TYPESCATEGORIES: {
        const currentNumConsumptions =
          grogConsumptionState.numConsumptionsCurrentDate()

        let screenTitle
        if (currentNumConsumptions != null && currentNumConsumptions > 0) {
          // User has previously added consumptions, they are now adding additional drinks
          // they had on this day
          screenTitle = 'So, what else did you drink that day?'
        } else {
          // User has not inputted any drinks yet (first time this screen appears for the
          // current date)
          screenTitle = 'What types of grog were you drinking on that last day?'
        }

        return (
          <MultipleChoice
            key="types-categories-selection"
            title={screenTitle}
            subtitle="(choose all the types of grog that you drank from the list below)"
            isMultiple={true}
            options={typesCategories.map((v) => ({
              id: v?.id ? `${currentScreenId}-${v?.id}` : undefined,
              image: v.image,
              label: v.name,
              value: v.name,
              voices: v?.voices,
              selected:
                currentConsumptionSelections.activeTypesCats.value.includes(v)
            }))}
            isCarousel={true}
            columnNum={typesCategories.length > 4 ? typesCategories.length : 4}
            setValue={(value) => {
              return currentConsumptionState.selections.activeTypesCats.set(
                consumptionStateUtils.mapChoiceNamesToChoices(
                  value,
                  typesCategories
                )
              )
            }}
          />
        )
      }

      case GrogDiaryStep.SUBTYPE: {
        const curTypeCatValue = currentConsumptionState.typeCat.value as
          | DeepReadonly<GrogTypeCategoryModel>
          | undefined

        const subTypesList = curTypeCatValue?.subTypes ?? []

        return (
          curTypeCat && (
            <MultipleChoice
              key="subtype-selection"
              title={`What types of <u>${curTypeCatValue?.name.toLocaleLowerCase()}</u> were you drinking that day?`}
              subtitle="(choose all the types from the list below)"
              isMultiple={true}
              options={subTypesList.map((v) => ({
                id: v?.id ? `${currentScreenId}-${v?.id}` : undefined,
                image: v.image,
                label: v.name,
                value: v.name,
                selected:
                  currentConsumptionSelections.activeSubTypes.value.includes(v),
                voices: v?.voices
              }))}
              setValue={(value) => {
                return currentConsumptionState.selections.activeSubTypes.set(
                  consumptionStateUtils.mapChoiceNamesToChoices(
                    value,
                    subTypesList
                  )
                )
              }}
              columnNum={4}
              isCarousel={true}
              optionalButtons={[
                {
                  id: 'skip-subtypes',
                  label: `I didn't have any ${curTypeCatValue?.name.toLocaleLowerCase()}`,
                  value: null
                }
              ]}
              onOptionalButtonSelect={(_) => {
                grogConsumptionState.skipStep()
              }}
            />
          )
        )
      }

      case GrogDiaryStep.BRAND: {
        let brandParentType: DeepReadonly<GrogSubTypeModel>
        if (curSubType.value) {
          brandParentType = curSubType.value
        } else {
          brandParentType = curTypeCat.value! as DeepReadonly<GrogSubTypeModel>
        }

        // Assert brands array is non-null
        assertNonNull(brandParentType.brands, 'brandParentType.brands')

        return (
          <MultipleChoice
            key="brand-selection"
            title={`What types of <u>${brandParentType.name.toLowerCase()}</u> were you drinking that day?`}
            isMultiple={true}
            options={brandParentType.brands.map((v) => ({
              image: v.image,
              label: v.name,
              value: v.name,
              selected:
                currentConsumptionSelections.activeBrands.value.includes(v)
            }))}
            setValue={(value) => {
              return currentConsumptionState.selections.activeBrands.set(
                consumptionStateUtils.mapChoiceNamesToChoices(
                  value,
                  brandParentType.brands!
                )
              )
            }}
            columnNum={4}
            isCarousel={true}
            optionalButtons={[
              {
                id: 'skip-brands',
                label: `I didn't have any ${brandParentType.name.toLocaleLowerCase()}`,
                value: null
              }
            ]}
            onOptionalButtonSelect={(_) => {
              grogConsumptionState.skipStep()
            }}
          />
        )
      }

      case GrogDiaryStep.PRODUCT: {
        let brandOptions: MultipleChoiceModel[]
        if (curBrand.value && curBrand.value.products) {
          brandOptions = curBrand.value.products.map((v, index) => ({
            image: v.image,
            label: v.name,
            value: `product_${index.toString()}`,
            selected: v === currentConsumptionState.product.value
          }))
        } else {
          brandOptions = []
        }

        let subtypeOptions: MultipleChoiceModel[]
        if (curSubType.value && curSubType.value.containers) {
          subtypeOptions = curSubType.value.containers.map((v, index) => ({
            image: v.carouselImage,
            label: `${v.localDisplayName || v.name} (${v.displayCapacity})`,
            value: `container_${index.toString()}`,
            selected: v === currentConsumptionState.container.value
          }))
        } else {
          subtypeOptions = []
        }

        let typeCatOptions: MultipleChoiceModel[]
        if (!curSubType.value && curTypeCat.value) {
          const curTypeCatValue =
            curTypeCat.value! as DeepReadonly<GrogSubTypeModel>
          typeCatOptions = curTypeCatValue.containers.map((v, index) => ({
            image: v.carouselImage,
            label: `${v.localDisplayName || v.name} (${v.displayCapacity})`,
            value: `container_${index.toString()}`,
            selected: v === currentConsumptionState.container.value
          }))
        } else {
          typeCatOptions = []
        }

        return (
          <MultipleChoice
            key="product-selection"
            title={`What did you mostly drink <u>${typeName}</u> out of?`}
            options={[...brandOptions, ...subtypeOptions, ...typeCatOptions]}
            isCarousel={true}
            setValue={([v]) => {
              const [type, index] = v.split('_')

              if (type === 'product' && curBrand.value) {
                currentConsumptionState.product.set(
                  curBrand.value.products[Number(index)]
                )
                currentConsumptionState.container.set(undefined)
              } else if (type === 'container' && curSubType.value) {
                currentConsumptionState.product.set(undefined)
                currentConsumptionState.container.set(
                  curSubType.value.containers[Number(index)]
                )
              } else if (type === 'container' && curTypeCat.value) {
                const curTypeCatValue =
                  curTypeCat.value! as DeepReadonly<GrogSubTypeModel>
                currentConsumptionState.product.set(undefined)
                currentConsumptionState.container.set(
                  curTypeCatValue.containers[Number(index)]
                )
              }
            }}
            columnNum={4}
            optionalButtons={[
              {
                id: 'skip-products',
                label: `I didn't have any ${typeName}`,
                value: null
              }
            ]}
            onOptionalButtonSelect={(_) => {
              grogConsumptionState.skipStep()
            }}
          />
        )
      }

      case GrogDiaryStep.PRODUCT_CONTAINER: {
        const minServingSelection = 1

        if (curProduct.value) {
          const maxProductsToDisplay = calcMaxDisplayContainers(
            curProduct.value.subContainer,
            curProduct.value.stepsPerSubContainer
          )

          return (
            <GrogSlider
              key={'product_slider'}
              title={`How much <u>${typeName}</u> were you drinking that day?`}
              drinkName={typeName}
              image={curProduct.value?.image}
              min={minServingSelection}
              max={maxProductsToDisplay}
              vertical={false}
              subContainer={curProduct.value.subContainer}
              stepsPerSubContainer={curProduct.value.stepsPerSubContainer}
              onOptionalButtonSelect={() => grogConsumptionState.skipStep()}
              setValue={curDrinkAmount.set}
            />
          )
        } else {
          // Get number of standard drinks in current container
          const containerStandardDrinks = calcStandardDrinks({
            container: {
              capacity: curContainer.value!.capacity,
              grog: grogConsumptionState.getContainerAlcoholAmount()!
            },
            alcoholPercentage: grogConsumptionState.getAlcoholPercentage()!
          })

          // Get the maximum number of containers to display on the screen
          // A custom container will have 1 "sub-container" implicitly
          const maxContainersToDisplay = calcMaxDisplayContainers(
            1,
            containerStandardDrinks
          )

          return (
            <GrogSlider
              key={'container_slider'}
              title={`How many ${
                curContainer.value?.localDisplayNamePlural
                  ? curContainer.value?.localDisplayNamePlural.toLowerCase()
                  : curContainer.value?.displayNamePlural.toLowerCase()
              } of <u>${typeName}</u> were you drinking that day?`}
              drinkName={typeName}
              image={curContainer.value?.carouselImage}
              min={minServingSelection}
              max={maxContainersToDisplay}
              vertical={false}
              onOptionalButtonSelect={() => grogConsumptionState.skipStep()}
              setValue={curDrinkAmount.set}
              customComponent={
                <Container
                  image={curContainer.value?.image}
                  fillMaskImage={curContainer.value?.fillMaskImage}
                  topBorder={curContainer.value?.topBorder}
                  bottomBorder={curContainer.value?.bottomBorder}
                  min={0}
                  max={100}
                  maxHeight={100}
                  drinkValue={
                    (curContainerAmount.value as readonly number[])[0] ||
                    (curContainerAmount.value as number)
                  }
                  fizzyDrinkValue={
                    (curContainerAmount.value as readonly number[])[1]
                  }
                  drinkColor={
                    curBrand.value?.colour || curSubType.value?.colour
                  }
                />
              }
            />
          )
        }
      }

      case GrogDiaryStep.CUSTOM_CONTAINER: {
        let typeCanHaveMixer = false
        if (
          curSubType.value?.addFizzyDrink ||
          (!typeCategoryHasSubTypes(curTypeCat.value) &&
            curTypeCat.value?.addFizzyDrink)
        ) {
          // Drink can have fizzy drink or juice added to it
          typeCanHaveMixer = true
        }

        return (
          <GrogSlider
            key={'container_vertical_slider'}
            title={`How much <u>${typeName}</u> did you put in it?`}
            subtitle={
              typeCanHaveMixer
                ? 'If you put any fizzy drink or juice, please add it as well.'
                : undefined
            }
            drinkName={typeName}
            image={curContainer.value?.image}
            fillMaskImage={curContainer.value?.fillMaskImage}
            topBorder={curContainer.value?.topBorder}
            bottomBorder={curContainer.value?.bottomBorder}
            vertical={true}
            addFizzyDrink={typeCanHaveMixer}
            setValue={curContainerAmount.set}
            onOptionalButtonSelect={() => grogConsumptionState.skipStep()}
            drinkColor={curBrand.value?.colour || curSubType.value?.colour}
            defaultValue={curContainerAmount.value}
          />
        )
      }

      case GrogDiaryStep.IS_GROUP: {
        const curIsGroup = currentConsumptionState.isGroup

        return (
          <MultipleChoice
            title={
              'Just want to check, is this what you had or what the whole group had?'
            }
            options={[
              {
                image: justMeImage,
                label: 'Just me',
                value: 'me',
                selected: curIsGroup.value === false
              },
              {
                image: groupImage,
                label: 'The group',
                value: 'group',
                selected: curIsGroup.value
              }
            ]}
            setValue={([value]) => {
              curIsGroup.set(value === 'group')
            }}
          />
        )
      }

      case GrogDiaryStep.SHARE: {
        if (people == null || people <= 1) {
          // This should not happen: throw an error
          throw new Error(
            '`people` state variable should be set and > 1 for group share'
          )
        }

        if (curDrinkAmount.value == null || curDrinkAmount.value <= 0) {
          // This ALSO should not happen: throw an error
          throw new Error(
            '`curDrinkAmount` should be set and > 0 for group share'
          )
        }

        const curShare = currentConsumptionState.share

        const minShareSelection = 1

        // // Calculate the minimum selection of the group share
        // // This code is ported from the original "Grog App"
        const defaultShareSelection = Math.floor(curDrinkAmount.value / people)

        if (
          curProduct.value &&
          curProduct.value.subContainer &&
          curProduct.value.stepsPerSubContainer
        ) {
          // The number of selections per individual image
          // - If a product is a multi-pack, this is the number of sub-containers
          // - Otherwise, this is the steps per sub-container (i.e. there is 1
          //   sub-container, such as a bottle)
          const numSelectionsPerImage = calcNumStepsPerProductImage(
            curProduct.value
          )

          // The maximum number of images to display on the screen
          const newMax = Math.ceil(curDrinkAmount.value / numSelectionsPerImage)

          return (
            <GrogSlider
              key={'product_slider'}
              title="Sometimes when people drink together, they all have the same amount. 
                     But sometimes, some people drink more than others. About how much did
                     you drink?"
              subtitle={`(${curProduct.value.displayCapacity})`}
              drinkName={typeName}
              image={curProduct.value?.image}
              min={minShareSelection}
              max={newMax}
              isShare={true}
              shareMax={curDrinkAmount.value}
              vertical={false}
              subContainer={curProduct.value?.subContainer}
              stepsPerSubContainer={curProduct.value?.stepsPerSubContainer}
              onOptionalButtonSelect={() => grogConsumptionState.skipStep()}
              setValue={curShare.set}
              defaultValue={defaultShareSelection}
            />
          )
        } else {
          return (
            <GrogSlider
              key={'container_slider'}
              title="Sometimes when people drink together, they all have the same amount. 
                     But sometimes, some people drink more than others. About how much did
                     you drink?"
              subtitle={`(${curContainer.value?.displayCapacity})`}
              drinkName={typeName}
              image={curContainer.value?.carouselImage}
              min={minShareSelection}
              max={curDrinkAmount.value}
              isShare={true}
              shareMax={curDrinkAmount.value}
              vertical={false}
              onOptionalButtonSelect={() => grogConsumptionState.skipStep()}
              setValue={curShare.set}
              defaultValue={defaultShareSelection}
              customComponent={
                <Container
                  image={curContainer.value?.image}
                  fillMaskImage={curContainer.value?.fillMaskImage}
                  topBorder={curContainer.value?.topBorder}
                  bottomBorder={curContainer.value?.bottomBorder}
                  min={0}
                  max={100}
                  maxHeight={100}
                  drinkValue={
                    Array.isArray(curContainerAmount.value)
                      ? curContainerAmount.value[0]
                      : curContainerAmount.value
                  }
                  fizzyDrinkValue={
                    (curContainerAmount.value as readonly number[])[1]
                  }
                  drinkColor={
                    curBrand.value?.colour || curSubType.value?.colour
                  }
                />
              }
            />
          )
        }
      }

      case GrogDiaryStep.MORE: {
        const curMore = currentConsumptionState.more

        return (
          <MultipleChoice
            title={'On the same day, was this all you had to drink?'}
            options={[
              {
                label: 'Yes',
                value: 'yes',
                selected: curMore.value === false
              },
              {
                label: 'No',
                value: 'no',
                selected: curMore.value
              }
            ]}
            setValue={([value]) => curMore.set(value === 'no')}
          />
        )
      }

      case GrogDiaryStep.DIARY: {
        if (date && diary && diary[date.toISOString()]) {
          return (
            <GrogDiary
              title="On the same day, was this all you had to drink?"
              date={date.toISOString()}
              consumptions={diary[date.toISOString()].consumptions}
              onDiaryIncrease={onDiaryIncrease}
              onDiaryDecrease={onDiaryDecrease}
              onYesClick={summaryScreenStepForward}
              onNoClick={() => {
                grogConsumptionState.resetState()
                stepForward(GrogDiaryStep.TYPESCATEGORIES)
              }}
            />
          )
        } else {
          return (
            <div>
              <h1>Error in Grog Diary:</h1>
              <p>No consumption found for timestamp {date?.toISOString()}</p>
            </div>
          )
        }
      }

      case GrogDiaryStep.OTHERDATE: {
        if (selectedDateList.length < requireDiary) {
          // This screen should not be showing up if we have not recorded enough
          // "detailed" consumptions
          throw new Error(
            `The number of selected dates (${selectedDateList.length}) should be ` +
              'greater than or equal to to the value of `requireDiary` ' +
              `(${requireDiary})`
          )
        }

        let title
        if (userCompletedAllDates()) {
          // All dates have been selected
          title = 'Thanks. Your Grog Diary has been recorded.'
        } else {
          title = 'What about the time before that?'
        }

        return (
          <AddMultiDate
            title={title}
            dates={selectedDates.map((d) => new Date(d))}
            addDate={() => {
              stepForward(GrogDiaryStep.LASTDAY)
            }}
            requiredDate={requireDiary + otherDayDiary}
            disabled={moreThan12Months}
          />
        )
      }

      default:
        break
    }
  }

  return (
    <div className={`drug-app-screen  ${styles.grogdiary}`}>
      <Screen
        currentScreenId={currentScreenId}
        headerProps={handleHeaderProp()}
        footerProps={handleFooterProps()}
      >
        {renderStep()}
      </Screen>
    </div>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  birthday: (state.surveyData && state.surveyData['birthday']) as string,
  events:
    state.projects?.find(
      (project) => project.id === state.surveyMetaData?.projectId
    )?.events ?? [],
  surveyData: state.surveyData,
  voiceOver: state.survey?.voiceOver,
  typesCategories:
    (
      (state.user?.id === 'demo' ? state.demoGrogShops : state.grogShops)?.find(
        (shop) => shop.Id === state.surveyMetaData?.shopId
      ) as GrogShopDataModel
    ).typesCategories ?? [],
  grogDiaryUserJourney: state.grogDiaryUserJourney ?? [
    { step: GrogDiaryStep.LASTDAY, skipped: false }
  ],
  grogDiaryCurrentState:
    state.grogDiaryCurrentState ?? GROG_DIARY_CURRENT_STATE_DEFAULT
})

export const GrogDiaryScreen = connect(mapStateToProps)(
  GrogDiaryScreenComponent
)
