import { createAsyncThunk } from '@reduxjs/toolkit'
import { surveyAPI } from 'api/client'
import type {
  LoginAPIResponseDataModel,
  UseAPIHookLoginDataModel,
  SyncSurveysAPIDataModel,
  LoginAPIResponseModel
} from 'api/client.model'
import { DEMO_USER } from 'shared/constants/Constants.d'
import { parseJwt } from 'shared/utils/decodeJWT/decodeJWT'
import { extractAssetUrls } from 'shared/utils/extractAssetUrls/extractAssetUrls'
import { AppAPIError } from './actionCreators.model'

const {
  canConnectToUmbraco,
  login,
  fetchSurveyData,
  fetchLocationData,
  fetchGrogShopData,
  fetchSLKListData,
  handleSurveySubmit,
  fetchTargets,
  handleSLKValidation
} = surveyAPI()

export const loginAPI = createAsyncThunk(
  'ra/login',
  async (loginDetails: UseAPIHookLoginDataModel, { rejectWithValue }) => {
    let decodeData: LoginAPIResponseDataModel | null = null

    if (!loginDetails.demoMode) {
      let loginResponse: LoginAPIResponseModel
      try {
        loginResponse = await login(loginDetails)
      } catch (err) {
        throw new AppAPIError(`Authenticate API Failed: ${err}`)
      }

      if (loginResponse.success === false) {
        // A custom message we can display to the user
        return rejectWithValue(loginResponse.message)
      }

      if (loginResponse.data) {
        decodeData = parseJwt(loginResponse.data)
      }
    } else {
      // Check if a connection to Umbraco is still possible to determine offline status
      if (!(await canConnectToUmbraco())) {
        throw new AppAPIError(
          'Cannot connect to Umbraco to download demo mode survey'
        )
      }

      decodeData = DEMO_USER
    }

    if (decodeData == null) {
      return rejectWithValue('Error sourcing authentication data from server')
    }

    // Login success
    return {
      userInfo: decodeData,
      demoMode: loginDetails.demoMode
    }
  }
)

export const fetchAllAppDataAPI = createAsyncThunk(
  'ra/fetchAllAppData',
  async (serviceId: string, { rejectWithValue }) => {
    let surveyManifestData
    let locationData
    let grogShopData
    let slkListData

    try {
      ;[surveyManifestData, locationData, grogShopData, slkListData] =
        await Promise.all([
          fetchSurveyData(serviceId),
          fetchLocationData(),
          fetchGrogShopData(serviceId),
          fetchSLKListData(serviceId)
        ])

      if (surveyManifestData.success === false) {
        throw new AppAPIError('unsuccessful retrieval of survey manifest data')
      } else if (!surveyManifestData.data) {
        throw new AppAPIError(
          'survey manifest API did not return survey manifest data'
        )
      } else if (!locationData.data) {
        throw new AppAPIError('location data API did not return location data')
      } else if (!grogShopData.data) {
        throw new AppAPIError(
          'Grog Shop data API did not return Grog Shop data'
        )
      } else if (!slkListData) {
        throw new AppAPIError('SLK list API did not return SLK data')
      }
    } catch (err) {
      // Some API has failed
      console.error(err)
      return rejectWithValue('Service API Failed')
    }

    // Get all URL assets
    const assetsInfo = extractAssetUrls(
      surveyManifestData.data,
      grogShopData.data,
      slkListData
    )

    // Successful download of data
    return {
      data: surveyManifestData.data,
      locations: locationData.data,
      grogshops: grogShopData.data,
      slks: slkListData,
      assetsInfo
    }
  }
)

export const fetchSurveyAPI = createAsyncThunk(
  'ra/fetchSurveyData',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await fetchSurveyData(id)
      if (response.data) {
        return { data: response.data }
      }
      return { error: 'Service API Failed' }
    } catch (err: any) {
      if (!err.response) {
        throw Error(`Service API Failed: ${err}`)
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const syncSurveysAPI = createAsyncThunk(
  'ra/syncSurveys',
  async (data: SyncSurveysAPIDataModel, { rejectWithValue }) => {
    const { serviceId, surveyResponses, projectSiteTargets } = data

    if (!serviceId) {
      return rejectWithValue('Surveys Sync API Failed')
    }

    const validResponses = surveyResponses
      ? surveyResponses.filter((res) => res.metadata!.status !== 'synced')
      : []

    const validProjectSiteTargets = projectSiteTargets ?? []

    try {
      const response = await handleSurveySubmit(serviceId, validResponses)

      if (response.success && response.result) {
        /**
         * FetchTargets will only be executed after the surveys are successfully synced.
         * It works like an extension that extends the behavior of surveys syncing;
         * its execution result will not have impact on the surveys syncing flow.
         */
        const targetsResponse = await fetchTargets(validProjectSiteTargets)

        const completedSurveys = response.result.completedSubmissions
        const incompleteSurveys = response.result.toBeContinueSubmissions
        const projectSiteTargetCollection = targetsResponse.projectSiteTargets

        return {
          data: {
            completedSubmissions: completedSurveys,
            toBeContinueSubmissions: incompleteSurveys
          },
          projectSiteTargets: projectSiteTargetCollection
        }
      }
      return rejectWithValue('Survey Sync API Failed')
    } catch (err: any) {
      if (!err.response) {
        throw Error(`Survey Sync API Failed: ${err}`)
      }
      return rejectWithValue(err)
    }
  }
)

export const validateSLK = createAsyncThunk(
  'ra/validateSLK',
  async (data: string | undefined, { rejectWithValue }) => {
    if (!data) {
      return rejectWithValue('Validate SLK API Failed')
    }

    try {
      return await handleSLKValidation(data)
    } catch (err: any) {
      if (!err.response) {
        throw Error(`Validate SLK API Failed: ${err}`)
      }
      return rejectWithValue(err)
    }
  }
)
