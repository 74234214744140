import styles from 'shared/styles/app.scss'

export const DEMO_MODE_SERVICE_ID =
  process.env.REACT_APP_DEMO_MODE_SERVICE_ID ||
  '0fd66239-f646-4afe-a2d4-ca6cd23853ff'

// API Endpoints
export const DEFAULT_EMPTY_IMAGE_PATH = '/path/to/image.png'

export const SURVEY_DATA_API_BASE =
  process.env.REACT_APP_SURVEY_DATA_API_BASE ||
  'https://dev-cms-drug-app-tool.sydney.edu.au'

export const SURVEY_DATA_API_ENDPOINT = '/umbraco/backoffice/api/data/service'

export const LOGIN_DATA_API_ENDPOINT = '/api/data/ra/authenticate'

export const LOCATION_DATA_API_ENDPOINT =
  '/umbraco/backoffice/api/data/location'

export const SURVEY_SUBMISSION_API_BASE =
  process.env.REACT_APP_SURVEY_SUBMISSION_API_BASE

export const SURVEY_SUBMISSION_API_ENDPOINT = '/submissions-v2'

export const FEEDBACK_SUBMISSION_API_ENDPOINT =
  '/umbraco/backoffice/api/data/feedback/create'

export const FORGOT_PIN_API_ENDPOINT = '/api/data/ra/reset-pin'

export const VALIDATE_SLK_API_ENDPOINT = '/api/slk/validate'

export const LIST_SLK_API_ENDPOINT = '/api/SLK'

// Survey Fields
export const LEAVE_SURVEY = 'leave'
export const CONTINUE_LATER = 'continue'

// Outro Fields
export enum UsingKeys {
  'grog' = 'GROG',
  'tobacco' = 'TOBACCO',
  'cannabis' = 'CANNABIS',
  'meth' = 'METH',
  'heroin' = 'HEROIN',
  'opioid' = 'OPIOIDS',
  'benzos' = 'BENZOS',
  'other' = 'OTHER'
}

export enum UsingTypes {
  grog = 'grog',
  tobacco = 'tobacco',
  cannabis = 'cannabis',
  meth = 'meth',
  heroin = 'heroin',
  opioids = 'opioids',
  benzos = 'benzos',
  other = 'other'
}

// Default colour data, in case the survey data/colour data doesn't exist
export const DEFAULT_COLOUR_INFO = {
  primaryColour: '#b58562',
  secondaryColour: '#222222'
} as const

// Alcohol Constant for Standard Drinks Calculation
export const ETHANOL_CONST = 0.789
export const STANDARD_DRINK_CONST = 10

// A default value of the Grog shop container image height in pixels
export const GROG_SHOP_CONTAINER_IMAGE_HEIGHT = 760

// A default value which will disable the "next" button on individual/"group share"
// screens of the Grog Diary (due to being fundamentally invalid)
export const GROG_DIARY_INVALID_SLIDER_SELECTION = 0

// A const value which will be passed to the "getNextScreenId" function on the
// GrogDiaryStep.OTHERDATE screen of the Grog Diary (GitHub issue #31)
export const GROG_DIARY_MORE_THEN_12_MONTHS_AGO = 'moreThan12MonthsAgo'

/**
 * The default "current state" for the Grog Diary. Note that the `consumption` prop is not
 * provided, as by default, this is `undefined`.
 */
export const GROG_DIARY_CURRENT_STATE_DEFAULT = {
  selections: {
    activeTypesCatsIds: [] as readonly number[],
    activeSubTypesIds: [] as readonly number[],
    activeBrandsIds: [] as readonly number[]
  }
} as const

export const GROG_DIARY_GROUP_PEOPLE_INDEX_RANGE = Array(10)
  .fill(0)
  .map((n, i) => i + 1)

/**
 * The timeout value, in milliseconds, for all assets to be cached if being downloaded for
 * the first time.
 *
 * Can be customised by the environment variable `REACT_APP_ASSET_CACHING_TIMEOUT_MS`. If
 * it is not set, the timeout defaults to 10 minutes (in milliseconds).
 */
export const CACHING_TIMEOUT_MS =
  Number(process.env.REACT_APP_ASSET_CACHING_TIMEOUT_MS) || 10 * 60 * 1000

/**
 * Different Targets for RAs:
 * RA-Dashboard,
 * RA-OverallProgress
 */
export enum RaTargetsType {
  Dashboard,
  OverallProgress
}

/**
 * An object representing the demo user used to access a demo survey.
 */
export const DEMO_USER = {
  id: 'demo',
  serviceId: DEMO_MODE_SERVICE_ID,
  username: 'demo',
  email: 'demo@demo.com'
} as const
