import React, { useContext, useState, useEffect, useCallback } from 'react'
import type {
  SectionOption,
  SectionProgressPropsModel
} from './SectionProgress.model'
import styles from './SectionProgress.module.scss'
import classnames from 'classnames'
import { Grid } from '@mui/material'
import { AudioContext } from 'context/AudioContext'
import { SectionItem } from './SectionItem/SectionItem.component'

const NUM_FULL_SIZE_OPTIONS = 4

export const SectionProgress: React.FC<SectionProgressPropsModel> = (props) => {
  const {
    className,
    title,
    subtitle,
    options,
    click = false,
    setOptionScreenId,
    setOptionValue
  } = props
  const { playAudio } = useContext(AudioContext)

  const [selectedOption, setSelectedOption] = useState<string>()

  /**
   * Highlighted options cater to the drugs a user consumed to be at the top of the Outro Tips page. (GitHub #211)
   * However, if the user consumed all the types of drugs listed in the survey, the highlighted options will still
   * appear under the title and the subtitle to get better in UX.
   */
  const highlightedOptions = options.filter((o) => o.highlighted)
  const normalOptions = options.filter((o) => !o.highlighted)

  const numOptionsClass =
    options.length < NUM_FULL_SIZE_OPTIONS
      ? 'section-progress--few-options' // 100%
      : 'section-progress--many-options' // 60%

  const handleItemClick = useCallback(
    (option: SectionOption) => {
      if (click) {
        if (option.id && option.voices) {
          playAudio?.(`${option.id}-option`)
        }
        setSelectedOption(option.value)
        setOptionScreenId(option.screenId)
        setOptionValue?.(option.value)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [click]
  )

  // When clicking is disabled, the component automatically selects the first
  // available option that is neither completed nor disabled.
  useEffect(() => {
    if (!click) {
      const value = options.find(
        (option) => !option.disabled && !option.completed
      )
      setOptionScreenId(value?.screenId)
      setOptionValue?.(value?.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={classnames(
        className,
        styles['section-progress'],
        styles[numOptionsClass]
      )}
    >
      {highlightedOptions.length > 0 && normalOptions.length > 0 && (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ lg: 3, md: 3, sm: 1, xs: 1 }}
          alignItems="stretch"
          justifyContent="center"
        >
          {highlightedOptions.map((option) => (
            <SectionItem
              option={option}
              noWrap={highlightedOptions.length > 4}
              key={`section-progress-option-section-1-${option.value}-highlighted`}
              isSelected={selectedOption === option.value}
              click={click}
              handleItemClick={handleItemClick}
            />
          ))}
        </Grid>
      )}
      <div className={styles['section-progress-titles']}>
        {title && (
          <h1
            className="screen-titles--title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        {subtitle && (
          <p
            className="screen-titles--subtitle"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        )}
      </div>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ lg: 3, md: 3, sm: 1, xs: 1 }}
        alignItems="stretch"
        justifyContent="center"
      >
        {highlightedOptions.length > 0 &&
          normalOptions.length === 0 &&
          highlightedOptions.map((option) => (
            <SectionItem
              option={option}
              key={`section-progress-option-section-2-${option.value}-highlighted`}
              isSelected={selectedOption === option.value}
              click={click}
              handleItemClick={handleItemClick}
            />
          ))}
        {normalOptions.map((option) => (
          <SectionItem
            option={option}
            key={`section-progress-option-section-${option.value}`}
            isSelected={selectedOption === option.value}
            click={click}
            handleItemClick={handleItemClick}
          />
        ))}
      </Grid>
    </div>
  )
}
