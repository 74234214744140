import React, { useCallback, useMemo, useState } from 'react'
import { Screen } from 'components/Screen/Screen.component'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'
import type { TipsScreenOption, TipsScreenPropsModel } from './TipsScreen.model'
import { SectionProgress } from 'components/SectionProgress/SectionProgress.component'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import type { SectionOption } from 'components/SectionProgress/SectionProgress.model'
import { useColourPicker } from 'context/ColourContext'
import type { SurveyState } from 'store/type'
import { connect, useDispatch } from 'react-redux'
import styles from './TipsScreen.module.scss'
import { UsingTypes } from 'shared/constants/Constants.d'
import { updateOutroUserJourney } from 'store/reducer'
import { InvalidArgumentError } from 'shared/types/errors'

const { getNextScreenId } = matchCondition()

export const TipsScreenComponent: React.FC<TipsScreenPropsModel> = (props) => {
  const {
    currentScreenId,
    headerProps,
    screenData,
    footerProps,
    shortcodes,
    surveyData,
    outroUserJourney
  } = props

  // A workaround to prevent re-rendering when navigating to next page.
  // Only the initial value of the prop `outroUserJourney` is needed
  // to show the progress of the current Tips screen.
  const [initialOutroUserJourney] = useState(outroUserJourney)

  const completedOutroSections = useMemo(
    () =>
      initialOutroUserJourney
        .filter((item) => item.completed)
        .map((j) => j.outroSection.toString()),
    [initialOutroUserJourney]
  )

  const dispatch = useDispatch()

  const { primaryColour, secondaryColour } = useColourPicker()

  const { title, subtitle, onlyShowUser, currentOutroSection, options } =
    screenData

  const [nextScreenId, setNextScreenId] = useState<string>()
  const [nextOutroSection, setNextOutroSection] = useState<string>()

  const currOutroSection = Object.keys(UsingTypes).includes(currentOutroSection)
    ? UsingTypes[currentOutroSection as keyof typeof UsingTypes]
    : null

  const optionValueInSurveyFieldArray = useCallback(
    (surveyField: string, optionValue: string): boolean => {
      const surveyFieldValue = surveyData?.[surveyField] ?? []
      if (!Array.isArray(surveyFieldValue)) {
        throw new InvalidArgumentError(
          `Survey value given by field '${surveyField}' is not an array`
        )
      }
      const surveyFieldArray = surveyFieldValue as readonly string[]
      return surveyFieldArray.includes(optionValue)
    },
    [surveyData]
  )

  const defineUsing = useCallback(
    (option: TipsScreenOption, defaultScreenId?: string) => {
      if (surveyData != null && option.dependingScreen) {
        if (
          optionValueInSurveyFieldArray(option.dependingScreen, option.value)
        ) {
          return {
            using: true,
            screenId: option.user
          }
        } else {
          return {
            using: false,
            screenId: option.nonUser
          }
        }
      }
      return {
        using: false,
        screenId: option.nonUser || defaultScreenId
      }
    },
    [optionValueInSurveyFieldArray, surveyData]
  )

  const formattedOptions = useMemo(() => {
    const defaultScreenId = getNextScreenId(
      undefined,
      footerProps.conditions,
      footerProps.nextScreenId
    )

    const sectionOptions = options
      .filter((option) => {
        if (
          !option.alwaysEnabled &&
          screenData.enabledTipsDependentScreen != null
        ) {
          // If an dependent screen for which outro tips should be shown is defined in the
          // screen data, use this to determine whether the current option should be shown
          return optionValueInSurveyFieldArray(
            screenData.enabledTipsDependentScreen,
            option.value
          )
        }

        // Otherwise, by default, show this option
        return true
      })
      .map((option) => {
        const usingStatus = defineUsing(option, defaultScreenId)
        const isViewed =
          completedOutroSections.includes(option.value) ||
          currentOutroSection === option.value

        // option.dependingScreen is only false for buttons to
        // navigate away from tips (no tips button). This button
        // should be filled using primaryColour when unselected
        // and secondaryColour when selected (GitHub #510)
        const colours = option.dependingScreen
          ? undefined
          : { fill: primaryColour, fillOnSelect: secondaryColour }

        return {
          label: option.label,
          value: option.value,
          icon: usingStatus.using ? option.image : undefined,
          screenId: usingStatus.screenId,
          id: option.id,
          voices: option.voices,
          disabled: isViewed,
          completed: isViewed,
          highlighted: usingStatus.using,
          ...colours
        } as SectionOption
      })

    if (onlyShowUser) {
      // Show the sections containing the grog, tobacco & drugs that the user is using
      return sectionOptions.filter((sectionOption) => sectionOption.highlighted)
    }

    return sectionOptions
  }, [
    completedOutroSections,
    currentOutroSection,
    defineUsing,
    footerProps.conditions,
    footerProps.nextScreenId,
    onlyShowUser,
    optionValueInSurveyFieldArray,
    options,
    primaryColour,
    screenData.enabledTipsDependentScreen,
    secondaryColour
  ])

  const updatedFooterProps = useMemo(() => {
    const updatedFooterProps = { ...footerProps }

    if (currOutroSection) {
      updatedFooterProps.onBackwardTransition = () => {
        // Pass a state update function when the Back button on an Outro Tips screen is clicked
        dispatch(
          updateOutroUserJourney({
            actionType: 'moving-backward',
            data: { currOutroSection }
          })
        )
      }
    }

    if (!nextScreenId) {
      updatedFooterProps.invalid = true
    } else {
      updatedFooterProps.nextScreenId = getNextScreenId(
        undefined,
        undefined,
        nextScreenId
      )

      if (nextOutroSection) {
        const selectedOutroSection = Object.keys(UsingTypes).includes(
          nextOutroSection
        )
          ? UsingTypes[nextOutroSection as keyof typeof UsingTypes]
          : null

        updatedFooterProps.onForwardTransition = () => {
          // Pass a state update function when the Next button on an Outro Tips screen is clicked
          dispatch(
            updateOutroUserJourney({
              actionType: 'moving-forward',
              data: {
                currOutroSection,
                nextOutroSection: selectedOutroSection
              }
            })
          )
        }
      }
    }

    return updatedFooterProps
  }, [currOutroSection, dispatch, footerProps, nextOutroSection, nextScreenId])

  return (
    <div className={`drug-app-screen ${styles.tipsscreen}`}>
      <Screen
        currentScreenId={currentScreenId}
        headerProps={headerProps}
        footerProps={updatedFooterProps}
      >
        <div className={styles['tipsscreen-container']}>
          <SectionProgress
            title={shortcodesRender(shortcodes, title)}
            subtitle={shortcodesRender(shortcodes, subtitle || undefined)}
            options={formattedOptions}
            click={true}
            setOptionScreenId={setNextScreenId}
            setOptionValue={setNextOutroSection}
          />
        </div>
      </Screen>
    </div>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  surveyData: state.surveyData,
  outroUserJourney: state.outroUserJourney ?? []
})

export const TipsScreen = connect(mapStateToProps)(TipsScreenComponent)
