import React, { useCallback, useEffect, useRef, useState } from 'react'
import type {
  AppDispatch,
  ISurveyMetaData,
  LoginStatus,
  SuccessfulLoginStatus,
  SurveyState,
  UserDataModel
} from 'store/type'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from './LoginScreen.module.scss'
import Box from '@mui/material/Box'
import type {
  LoadingActions,
  LoginScreenComponentProps
} from './LoginScreen.model'
import {
  FetchingManifestState,
  NarrowingProjectsState
} from './LoginScreen.model'
import { Pin } from 'components/Pin/Pin.component'
import { AutoComplete } from 'components/AutoComplete/AutoComplete.component'
import { fetchAllAppDataAPI, loginAPI } from 'store/actionCreators'
import { colors } from 'shared/theme/theme'
import { Alert } from 'components/Alert/Alert.component'
import {
  FIRST_TIME_LOGIN_WARNING,
  OFFLINE_PIN_WARNING,
  OFFLINE_USER_WARNING,
  OFFLINE_DEMO_WARNING
} from 'shared/constants/messages/Messages.d'
import {
  updateEmailSuggestion,
  updateLastLoginStatus,
  updateProjects,
  updateSurvey,
  updateSurveyMetadata,
  updateUser
} from 'store/reducer'
import type {
  ProjectDataModel,
  ProjectSiteModel,
  ResearchAssistantDataModel
} from 'api/client.model'
import { validateEmail } from 'shared/utils/validateValue/validateValue'
import { Button } from 'components/Button/Button.component'
import classnames from 'classnames'
import { InitialisationScreen } from 'components/ra-dashboard/Initialisation/InitialisationScreen/InitialisationScreen.component'
import { Checkbox, FormControlLabel } from '@mui/material'
import { storeSupportsRaModeOfflineSelector } from 'store/utils'
import { DeepReadonly } from 'shared/types/general'

const LoginScreenComponent: React.FC<LoginScreenComponentProps> = (props) => {
  const {
    service,
    demoService,
    offline,
    lastLoginStatus,
    loginAttempt,
    emailSuggestions
  } = props

  const navigate = useNavigate()
  const dispatch: AppDispatch = useDispatch()

  const shouldCacheAssets = useRef<boolean>(false)

  const [fetchingManifest, setFetchingManifest] =
    useState<FetchingManifestState>(FetchingManifestState.NOT_STARTED)
  const [narrowingProjects, setNarrowingProjects] =
    useState<NarrowingProjectsState>(NarrowingProjectsState.NOT_STARTED)
  const [redownloadCache, setRedownloadCache] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [demoModeLoading, setDemoModeLoading] = useState<boolean>(false)

  /**
   * Retrieves the last email from the emailSuggestions array,
   * and if it exists, sets it as the initial email.
   *
   * Maintains behaviour even if the user attempts to log in after logging
   * out.
   */
  const setInitEmailValue = (): string | undefined => {
    const lastEmail = emailSuggestions?.[0]
    if (lastEmail != null) {
      return lastEmail
    }

    return undefined
  }

  const [email, setEmail] = useState<string | undefined>(setInitEmailValue)
  const [pin, setPin] = useState<string>()

  const [alertText, setAlertText] = useState<string>()
  const [demoAlertText, setDemoAlertText] = useState<string>()

  // Check if the Redux store has required data for RA logged-in input
  const storeSupportsOfflineRaUse = useSelector(
    storeSupportsRaModeOfflineSelector
  )

  /**
   * Function to determine whether downloading the survey manifest from Umbraco/the API
   * should be skipped or not.
   *
   * The manfifest download should be skipped in the following scenarios:
   *
   * - The user is entering demo mode - in this case, we **always** re-download the
   *   Umbraco downloads, as we assume entering demo mode requires an Internet connection,
   *   OR,
   * - There is some previous locally-stored data which would allow for the app to be used
   *   fully offline, AND,
   * - The user has NOT opted to re-download items from Umbraco via the checkbox on the
   *   login screen.
   *
   * @returns `true` if a manifest from Umbraco/the API should be skipped, `false` if it
   * should be downloaded.
   */
  const shouldSkipUmbracoDownloads = () =>
    !demoModeLoading && storeSupportsOfflineRaUse && !redownloadCache

  /**
   * Validate input inside of the login form by checking whether the email address and PIN
   * are of a valid format, and if successfully, begin the login process for the app.
   *
   * If offline, this function will trigger the offline login process for the app.
   */
  const handleFormValidation = () => {
    // Lack of either email or pin
    if (!email || !pin || (pin && pin.length !== 4)) {
      setAlertText('Please fill in both email and pin to continue')
      return
    }

    // Email is not in the correct format
    if (!validateEmail(email)) {
      setAlertText('Please fill in a valid email address')
      return
    }

    setLoading(true)

    dispatch(loginAPI({ Username: email, Pin: pin }))

    if (offline) {
      validateLogin()
    }
  }

  /**
   * Check whether the provided Research Assistant data model's email matches the email
   * provided by the user in the "Email" input of the login screen.
   *
   * @param ra - A Research Assistant data model object.
   *
   * @returns `true` if the login screen email input matches the email in the RA data
   * model object (case-insensitive), `false` otherwise.
   */
  const raEmailMatchesProvidedEmail = useCallback(
    (ra: ResearchAssistantDataModel) =>
      ra.email.toLowerCase() === email?.toLowerCase(),
    [email]
  )

  /**
   * Updates the last login status, as well as the logged-in user, in Redux.
   *
   * @param lastLoginStatus - An object representing the last login status to record in
   * Redux.
   * @param userInfo - An object representing the information of a user to record as
   * logged in in Redux.
   */
  const updateReduxLoginInfo = (
    lastLoginStatus: DeepReadonly<LoginStatus>,
    userInfo: DeepReadonly<UserDataModel>
  ) => {
    dispatch(updateLastLoginStatus(lastLoginStatus))
    dispatch(updateUser(userInfo))
  }

  /**
   * Handle the logic required to log in to the app when in an offline context, and
   * returns whether such a log in was successful.
   *
   * An offline login is successful if a survey manifest has been previously downloaded,
   * and the email + PIN provided for an RA login matches that of an RA in the downloaded
   * manifest data. Otherwise, if the manifest has not been previously downloaded, or the
   * login details were not found, the offline login attempt is rendered invalid.
   *
   * Note that in order to use demo mode, you must be connected to the Internet, so
   * launching demo mode from an offline state is impossible.
   *
   * @returns `true` if the offline login attempt is successful, `false` otherwise.
   */
  const handleOfflineLogin = (): boolean => {
    if (demoModeLoading) {
      // Demo mode should NEVER be allowed to be used offline
      setDemoAlertText(OFFLINE_DEMO_WARNING)
      return false
    }

    if (!service) {
      setAlertText(FIRST_TIME_LOGIN_WARNING)
      return false
    }

    let valid = false
    let message = OFFLINE_USER_WARNING
    let userInfo: DeepReadonly<ResearchAssistantDataModel> | null = null

    raProjectSearchLoop: for (const project of service.projects) {
      if (project.sites == null) {
        // No sites: continue
        continue
      }

      for (const site of project.sites) {
        const matchedEmailRa = site.researchAssistants.find(
          raEmailMatchesProvidedEmail
        )

        if (matchedEmailRa == null) {
          // RA matching email not found in this site
          continue
        }

        // Matched RA - check PIN
        if (matchedEmailRa.pin === pin) {
          userInfo = matchedEmailRa
          valid = true
        } else {
          message = OFFLINE_PIN_WARNING
        }

        // Break outer loop using labelled break
        break raProjectSearchLoop
      }
    }

    if (!valid) {
      setAlertText(message)
    }

    if (valid && userInfo != null) {
      // Register the user as logged-in from the offline data
      updateReduxLoginInfo({ loggedIn: true, serviceId: service.id }, userInfo)
    }

    return valid
  }

  /**
   * Obtain an object with references to functions to set the current loading and/or alert
   * text state on the login screen, depending on whether or not the user is launching
   * demo mode, or a standard login.
   *
   * @returns An object matching the {@link LoadingActions} interface containing
   * references to the correct React `setState` functions for the "currently loading" and
   * alert text state.
   */
  const getLoadingActions = (): LoadingActions => {
    if (demoModeLoading) {
      return {
        setLoading: setDemoModeLoading,
        setAlertText: setDemoAlertText
      }
    }

    return {
      setLoading,
      setAlertText
    }
  }

  /**
   * Validate the login of a user to the application.
   *
   * If the email and PIN matches a valid user, this function will trigger the manifest to
   * be downloaded again if the user is logging in for the first time, is entering demo
   * mode, or has requested to re-download the manifest data that is already stored on the
   * local device.
   *
   * Otherwise, an appropriate alert message will be displayed on screen, and the screen
   * will no longer be displayed.
   */
  const validateLogin = () => {
    if (offline == null) {
      return
    }

    // Choose "set state" actions based on whether the app is being launched in demo mode
    // or not
    const loadingActions = getLoadingActions()

    let valid = false

    if (lastLoginStatus?.loggedIn) {
      valid = true
    } else {
      if (offline) {
        console.log('App is offline - logging in from locally downloaded data')
        valid = handleOfflineLogin()
      } else {
        loadingActions.setAlertText(lastLoginStatus?.errorMessage)
      }
    }

    if (valid) {
      // The login must be successful
      const successfulLoginStatus = lastLoginStatus as SuccessfulLoginStatus

      // Begin dispatching the survey ONLY IF
      // - they are online AND
      // - they are entering demo mode OR
      // - it is their first-time login OR
      // - the user explicitly wanted to re-download the Umbraco manifest/assets
      if (!offline && !shouldSkipUmbracoDownloads()) {
        setFetchingManifest(FetchingManifestState.STARTED)

        // Only fetch data from API if app is online
        dispatch(fetchAllAppDataAPI(successfulLoginStatus.serviceId)).then(() =>
          setFetchingManifest(FetchingManifestState.FINISHED)
        )

        // Set the ref for tracking whether to cache assets to `true`
        shouldCacheAssets.current = true
      } else {
        // Manifest has technically "finished" caching
        setFetchingManifest(FetchingManifestState.FINISHED)
      }
    } else {
      // Not logging in, so disable loading
      loadingActions.setLoading(false)
    }
  }

  /**
   * Handle an action of a user indicating they had forgotten their PIN by navigating to
   * the "forgot PIN" endpoint.
   */
  const handleForgotPin = () => {
    navigate('/ra-dashboard/forgot-pin')
  }

  /**
   * Perform some actions upon the "enter demo mode" button being clicked, including
   * setting the loading state of the screen, clearing any alert text, and checking
   * whether a connection to download the demo mode manifes tis available.
   */
  const onDemoModeClick = () => {
    setDemoModeLoading(true)
    setDemoAlertText(undefined)
    dispatch(loginAPI({ Username: 'demo', Pin: 'demo', demoMode: true }))
  }

  useEffect(() => {
    const selectedService = demoModeLoading ? demoService : service

    if (
      selectedService == null ||
      lastLoginStatus == null ||
      !lastLoginStatus.loggedIn
    ) {
      // We need these props to be defined (service has been downloaded, and a login has
      // been attempted), and if a login was attempted, it was valid
      return
    }

    if (fetchingManifest != FetchingManifestState.FINISHED) {
      // Still fetching the manifest, don't do anything yet
      return
    }

    /**
     * Narrow down the list of available projects and sites to select to only those which
     * are assigned to the RA who has successfully logged in.
     */
    const narrowDownStandardProjects = () => {
      setNarrowingProjects(NarrowingProjectsState.STARTED)

      const projects: DeepReadonly<ProjectDataModel>[] = []

      for (const project of selectedService.projects) {
        const raSpecificProject = {
          ...project,
          sites: [] as DeepReadonly<ProjectSiteModel>[]
        }

        if (project.sites == null) {
          // No sites for the current project - ignore
          continue
        }

        for (const site of project.sites) {
          const raMatchingCredentials = site.researchAssistants.find(
            (ra) => raEmailMatchesProvidedEmail(ra) && ra.pin === pin
          )

          if (raMatchingCredentials == null) {
            // RA matching credentials not found at this site
            continue
          }

          // Otherwise, RA's credentials matches those provided in the login screen
          raSpecificProject.sites.push(site)
        }

        if (raSpecificProject.sites.length > 0) {
          // Add project as selectable
          projects.push(raSpecificProject)
        }
      }

      dispatch(updateProjects(projects))

      setNarrowingProjects(NarrowingProjectsState.FINISHED)
    }

    /**
     * Perform a set of actions after the manifest is downloaded when logging in to the
     * application as an RA, including narrowing down projects/sites to only those
     * assigned to the logged-in RA, and navigating to the next-appropriate screen after
     * the projects have been narrowed down.
     */
    const handleStandardPostManifestDownload = () => {
      if (narrowingProjects == NarrowingProjectsState.NOT_STARTED) {
        // Fetching the manifest has finished OR it already existed. Begin narrowing down
        // projects.
        setAlertText(undefined)
        narrowDownStandardProjects()

        return
      }

      if (narrowingProjects == NarrowingProjectsState.FINISHED) {
        const navigateRoute = shouldCacheAssets.current
          ? '/ra-dashboard/loading-assets'
          : '/ra-dashboard/select-project'

        navigate(navigateRoute)
        setLoading(false)
      }
    }

    /**
     * Perform a set of actions after the manifest is downloaded when launching demo mode,
     * including creating dummy metadata, and navigating to the next appropriate screen.
     */
    const handleDemoPostManifestDownload = () => {
      const project = selectedService.projects.at(0)
      if (project == null) {
        // Error: no project defined for the demo service
        setDemoAlertText('Error: no project defined for demo mode service')
        setDemoModeLoading(false)
        return
      }

      if (project.survey == null) {
        // Error: no survey defined for the demo project
        setDemoAlertText('Error: no survey defined for demo mode project')
        setDemoModeLoading(false)
        return
      }

      // Projects for demo mode is an array with only the first project from the demo mode
      // service
      dispatch(updateProjects([project]))

      dispatch(updateSurvey(project.survey))

      const metadata: ISurveyMetaData = {
        surveyId: project.survey.id,
        serviceId: selectedService.id,
        projectId: project.id,
        siteId:
          project.sites && project.sites.length > 0 ? project.sites[0].id : '',
        shopId:
          project.shopId != null && project.shopId !== 0
            ? project.shopId
            : undefined
      }

      dispatch(updateSurveyMetadata(metadata))

      // Navigate to the demo survey entrypoint
      const navigateRoute = shouldCacheAssets.current
        ? '/ra-dashboard/loading-assets'
        : '/survey/demo'
      navigate(navigateRoute)
      setDemoModeLoading(false)
    }

    const handlePostManifestDownloadFunction = demoModeLoading
      ? handleDemoPostManifestDownload
      : handleStandardPostManifestDownload

    handlePostManifestDownloadFunction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    demoModeLoading,
    service,
    lastLoginStatus,
    fetchingManifest,
    narrowingProjects,
    raEmailMatchesProvidedEmail,
    pin,
    demoService
  ])

  useEffect(() => {
    if (offline != null) {
      validateLogin()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offline, lastLoginStatus, loginAttempt])

  /**
   * Updates the email suggestions list upon successful user login.
   *
   * When a user successfully logs in (`lastLoginStatus.loggedIn` is true)
   * and the provided email is valid, it dispatches an action to add the
   * email to the `emailSuggestions` array in the Redux store.
   */
  useEffect(() => {
    if (lastLoginStatus?.loggedIn && email && validateEmail(email)) {
      dispatch(updateEmailSuggestion({ email }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastLoginStatus, email])

  /**
   * Handle setting the email on the login screen, simultaneously clearing any alerts
   * which may be present on the screen.
   *
   * @param inputEmail - The email value entered in the login screen.
   */
  const handleSetEmail = (inputEmail: string) => {
    setEmail(inputEmail)
    setAlertText(undefined)
  }

  /**
   * Handle setting the PIN on the login screen, simultaneously clearing any alerts
   * which may be present on the screen.
   *
   * @param inputPin - The PIN value entered in the login screen.
   */
  const handleSetPin = (inputPin: string) => {
    setPin(inputPin)
    setAlertText(undefined)
  }

  return (
    <InitialisationScreen
      mainWrapper={{
        title: 'Login',
        additionalStylingClasses: styles['pinscreen']
      }}
      contentDivClasses={styles['pinscreen-wrapper']}
      card={{
        title: 'Research Assistant Login',
        button: {
          disabledCondition: loading,
          text: loading ? 'Logging in ...' : 'Login',
          onClick: handleFormValidation
        },
        extraAction: {
          title: 'forgot your PIN?',
          onActionClick: handleForgotPin
        },
        additionalStylingClasses: styles['pinscreen-card']
      }}
      {...(alertText && { alert: { text: alertText } })}
      belowCardChildren={
        <div className={styles['pinscreen-demo-button-wrapper']}>
          {demoAlertText && (
            <Alert
              className={styles['pinscreen-alert']}
              fullWidth={true}
              text={demoAlertText}
              backgroundColor={colors.crimson}
            />
          )}
          <Button
            className={classnames(
              'align-center',
              styles['pinscreen-demo-button']
            )}
            style={{
              backgroundColor: colors.capePalliser,
              color: `${colors.white} !important`
            }}
            onClick={onDemoModeClick}
            disabled={demoModeLoading}
          >
            {demoModeLoading ? 'Entering demo mode' : 'Or start demo mode'}
          </Button>
        </div>
      }
    >
      <Box className={styles['pinscreen-info']} component="form">
        <AutoComplete
          className={styles['pinscreen-text']}
          dataList={emailSuggestions!}
          defaultValue={email}
          inputOption={{
            label: 'Enter email',
            required: true,
            allowMultipleLines: false
          }}
          textFieldProps={{
            required: true,
            fullWidth: true,
            type: 'email',
            autoFocus: true
          }}
          setValue={handleSetEmail}
          freeEntry
        />
        <Pin
          title="4-digit PIN code"
          setValue={handleSetPin}
          isPassword={true}
        />

        <FormControlLabel
          className={styles['pinscreen-re-cache-checkbox']}
          control={
            <Checkbox
              checked={redownloadCache}
              disabled={!storeSupportsOfflineRaUse}
              onChange={() => setRedownloadCache((value) => !value)}
              size="large"
            />
          }
          label="Re-cache manifest and assets from Umbraco? (only available after
                 first-time login)"
        />
      </Box>
    </InitialisationScreen>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  service: state.service,
  demoService: state.demoService,
  offline: state.offline,
  lastLoginStatus: state.lastLoginStatus,
  loginAttempt: state.loginAttempt,
  emailSuggestions: state.emailSuggestions ?? []
})

export const LoginScreen = connect(mapStateToProps)(LoginScreenComponent)
