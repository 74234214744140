import React, { useId } from 'react'
import type { AnalysisProps } from './Analysis.model'
import Box from '@mui/material/Box'
import { AnalysisBorderLinearProgress } from './AnalysisBorderLinearProgress/AnalysisBorderLinearProgress.component'

// Minimum expected value of a progress component
const MIN_VALUE_OF_PROGRESS = 0
// Maximum expected value of a progress component
const MAX_VALUE_OF_PROGRESS = 100

export const Analysis: React.FC<AnalysisProps> = (props) => {
  const {
    title,
    values,
    sectionIndex,
    renderQualifier,
    drawProgressBar,
    stylingClasses
  } = props

  const analysisLabelId = useId()

  /**
   *  Clamp a percentage value to 0 - 100, which the progress components can accept.
   *
   * @param percentageValue A fraction scaled by a factor of 100.
   *
   * @returns A clamped progress value between 0 and 100.
   */
  const clampProgressValue = (percentageValue: number): number =>
    Math.max(
      MIN_VALUE_OF_PROGRESS,
      Math.min(percentageValue, MAX_VALUE_OF_PROGRESS)
    )

  return (
    <div className={stylingClasses?.mainDiv}>
      <p>{title}</p>
      <div className={stylingClasses?.wrapperDiv}>
        {values.map((item, index) => (
          <Box
            key={`${renderQualifier}-analysis-section-${sectionIndex}-item-${index}`}
            sx={{ width: '100%' }}
          >
            <label id={analysisLabelId} className={stylingClasses?.labels}>
              <span>{item.label}</span>
              <span>{`${item.quantity} / ${item.count}`}</span>
            </label>

            {drawProgressBar && (
              <AnalysisBorderLinearProgress
                className={stylingClasses?.progressBar}
                variant="determinate"
                value={
                  item.count > 0
                    ? clampProgressValue((item.quantity / item.count) * 100)
                    : 0
                }
                aria-labelledby={analysisLabelId}
              />
            )}
          </Box>
        ))}
      </div>
    </div>
  )
}
