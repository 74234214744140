import React, { useState, useEffect, useMemo, useCallback } from 'react'
import 'shared/styles/app.scss'
import { useLocation, Route, Routes, matchPath } from 'react-router-dom'
import { ScreenComponent, ScreenType } from 'screens/survey/type.d'
import { connect } from 'react-redux'
import type {
  GrogShopDataModel,
  GrogSubTypeModel,
  GrogTypeCategoryModel,
  SpecialEventDataModel,
  SurveyDataScreenModel,
  SurveyVoiceModel
} from 'api/client.model'
import type { FooterPropsModel } from 'components/Footer/Footer.model'
import type { SurveyState } from 'store/type.d'
import type { AudioContextProps } from 'context/AudioContext'
import { AudioContext } from 'context/AudioContext'
import { ColourContext } from 'context/ColourContext'
import {
  loadGrogAudio,
  loadGrogDiaryAudio,
  loadScreenAudio
} from 'shared/utils/loadScreenAudio/loadScreenAudio'
import {
  calculateSpecialEvent,
  parseSpecialEvent
} from 'shared/utils/calculateSpecialEvent/calculateSpecialEvent'
import { DEFAULT_COLOUR_INFO } from 'shared/constants/Constants.d'
import 'shared/styles/app.scss'
import type { DeepReadonly } from 'shared/types/general'

const InitialSurveySetup = (
  props: SurveyState & {
    demoMode?: boolean
    specialEvents: DeepReadonly<SpecialEventDataModel[]> | null
    typesCategories: DeepReadonly<
      (GrogTypeCategoryModel | GrogSubTypeModel)[]
    > | null
  }
) => {
  const { survey, demoMode = false, specialEvents } = props

  // Need to set this outside the above destructure as default assignment only works when
  // an object property is `undefined`, not `null`.
  const typesCategories = props.typesCategories ?? []

  // Set current dynamic event from list of events
  const specialEvent = useMemo(() => {
    const nonNullSpecialEvents = specialEvents ?? []
    return calculateSpecialEvent(nonNullSpecialEvents)
  }, [specialEvents])

  const location = useLocation()

  const startScreenData = survey?.screens[0]

  const [screenData, setScreenData] = useState<
    SurveyDataScreenModel | undefined
  >(startScreenData)
  const [progress, setProgress] = useState<number>(0)
  const [voice, setVoice] = useState<string>(
    survey ? survey.voiceOver[0].value : 'enMale'
  )
  const [audio, setAudio] = useState<AudioContextProps>({ files: {} })
  const [activeAudio, setActiveAudio] = useState<HTMLAudioElement | undefined>()
  const [voiceMute, setVoiceMute] = useState<boolean>(false)
  const [screenAudioKey, setScreenAudioKey] = useState<string | undefined>(
    undefined
  )

  // Set `hasAudio` based on value of these props
  const hasAudio = Boolean(
    (screenData?.id && audio.files[screenData.id]?.[voice]) ||
      (screenAudioKey && audio.files[screenAudioKey]?.[voice])
  )

  const findScreen = (id: string): SurveyDataScreenModel | undefined => {
    return survey?.screens?.find((item) => item.id === id)
  }

  // The voice overs filtered for the current screen (memoised)
  const filteredVoiceOvers = useMemo((): DeepReadonly<SurveyVoiceModel[]> => {
    if (screenData == null || screenData.voices == null || survey == null) {
      return []
    }

    const validVoices = Object.keys(screenData.voices)
    return survey.voiceOver.filter((voiceItem) =>
      validVoices.includes(voiceItem.value)
    )
  }, [screenData, survey])

  const stopAudio = useCallback(() => {
    // Stop existing audio from playing
    if (activeAudio) {
      activeAudio.pause()
    }
  }, [activeAudio])

  const playAudio = useCallback(
    (key?: string, newVoice: string | undefined = undefined) => {
      newVoice = newVoice || voice
      stopAudio()

      if (key) {
        const file = audio.files[key]?.[newVoice]
        if (file) {
          const audioElement = new Audio(file)
          audioElement.currentTime = 0

          // Cross-origin = anonymous to help with Workbox caching from service worker
          audioElement.crossOrigin = 'anonymous'

          // Load the audio clip from the cache
          audioElement.load()

          // Play the audio clip
          audioElement
            .play()
            .then(() => setActiveAudio(audioElement))
            .catch((e) => console.warn(`Failed to play audio`, e))
        }
      }
    },
    [audio.files, stopAudio, voice]
  )

  const Component: React.FC<any> = screenData
    ? ScreenComponent[Object.keys(screenData.data)[0] as ScreenType]
    : ('div' as any)

  // Props to pass to the `Header` component (memoised)
  const headerProps = useMemo(
    () => ({
      nextScreenId: screenData?.nextScreen?.nextScreenId,
      progress,
      voice,
      mute: voiceMute,
      helpText: screenData?.helpText,
      voiceOver: filteredVoiceOvers,
      playAudio,
      setVoice,
      setMute: setVoiceMute
    }),
    [
      filteredVoiceOvers,
      playAudio,
      progress,
      screenData?.helpText,
      screenData?.nextScreen?.nextScreenId,
      voice,
      voiceMute
    ]
  )

  /**
   * Generate the appropriate props to pass to the `Footer` component to be rendered
   * by this component.
   *
   * @param data - The data for the current screen in the survey, or `undefined` if it is
   * unavailable.
   * @param firstscreen - Whether the screen is the first screen in the survey or not.
   *
   * @returns An object representing all props to pass to the `Footer` component to be
   * rendered.
   */
  const generateFooterProps = useCallback(
    (
      data?: SurveyDataScreenModel,
      firstscreen?: boolean
    ): FooterPropsModel => ({
      id: data?.id,
      surveyEnd: screenData?.surveyEnd,
      demoMode,
      firstscreen,
      screenName: screenData?.surveyField,
      followUpQuestionName: screenData?.data.followUpQuestion.surveyField,
      nextScreenId: screenData?.nextScreen?.nextScreenId,
      conditions: screenData?.nextScreen?.isConditional
        ? screenData.nextScreen.conditions
        : undefined,
      backButtonHidden: data?.backButtonDisabled
    }),
    [
      demoMode,
      screenData?.data.followUpQuestion.surveyField,
      screenData?.nextScreen,
      screenData?.surveyEnd,
      screenData?.surveyField
    ]
  )

  // The footer props for the starting screen
  const startScreenFooterProps = useMemo(
    () => generateFooterProps(startScreenData, true),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [generateFooterProps, startScreenData?.id]
  )

  // The footer props for the current screen
  const footerProps = useMemo(
    () => generateFooterProps(screenData),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [generateFooterProps, screenData?.id]
  )

  const calculateProgress = (sectionId: string) => {
    let totalSections = survey?.screens.map((screen) => screen.section.id)
    totalSections = Array.from(new Set(totalSections))
    const sectionIndex = totalSections.findIndex((each) => each === sectionId)
    return ((sectionIndex + 1) / totalSections.length) * 100
  }

  /** Update Screen Data based on Route */
  useEffect(() => {
    const path = matchPath(
      `survey/${demoMode ? 'demo/' : ''}:id`,
      location.pathname
    )

    const screensData = survey?.screens

    if (screensData) {
      if (path?.params.id) {
        const screen = findScreen(path.params.id)

        if (screen) {
          const parsedScreen = parseSpecialEvent(screen, specialEvent)

          setProgress(calculateProgress(parsedScreen.section.id))
          setScreenData(parsedScreen)
        } else {
          alert(`Unknown screen: ${path.params.id}`)
        }
      } else {
        const parsedScreen = startScreenData
          ? parseSpecialEvent(startScreenData, specialEvent)
          : startScreenData
        setScreenData(parsedScreen)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  // Load all audio screens
  useEffect(() => {
    // Load audio for all screens
    if (survey?.screens) {
      Promise.all(
        survey.screens.map((screen) => {
          loadScreenAudio(audio, screen, specialEvent?.id)
          if (screen.data?.grogDiary && screen.id) {
            loadGrogDiaryAudio(audio, screen.id, screen)
            loadGrogAudio(audio, screen.id, typesCategories)
          }
        })
      ).then(() => setAudio(audio))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [survey])

  useEffect(() => {
    if (activeAudio) activeAudio.muted = voiceMute
  }, [activeAudio, voiceMute])

  useEffect(() => {
    if (activeAudio) {
      activeAudio.onended = () => {
        setActiveAudio(undefined)
      }

      activeAudio.onpause = () => {
        setActiveAudio(undefined)
      }
    }
  }, [activeAudio])

  // Modify the global CSS variables for primary and secondary colours
  useEffect(() => {
    const screenColours = screenData?.section ?? DEFAULT_COLOUR_INFO

    document.documentElement.style.setProperty(
      '--theme-primary-colour',
      screenColours.primaryColour
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenData?.section.id])

  const colourContextMemoisedValue = useMemo(
    // Return the section data - by structural typing, this satsifies the `ColourInfo`
    // type
    // The `useMemo` hook will ensure that the section data object passed to
    // `ColourContext.Provider` does not unnecessarily change until the section ID changes
    // If, for some reason, the section data is `undefined`, return fallback colours
    // sourced from the app
    () => screenData?.section ?? DEFAULT_COLOUR_INFO,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [screenData?.section.id]
  )

  const ctxValue = {
    files: audio.files,
    playAudio,
    stopAudio,
    hasAudio,
    activeAudio,
    screenAudioKey,
    setScreenAudioKey
  }

  return (
    <ColourContext.Provider value={colourContextMemoisedValue}>
      <AudioContext.Provider value={ctxValue}>
        <Routes>
          <Route
            index
            element={
              <Component
                key={`survey-screen-${startScreenData?.id}`}
                currentScreenId={startScreenData?.id}
                name={screenData?.surveyField}
                screenData={
                  startScreenData?.data[Object.keys(startScreenData.data)[0]]
                }
                followUpQuestion={startScreenData?.data.followUpQuestion}
                headerProps={headerProps}
                footerProps={startScreenFooterProps}
                shortcodes={screenData?.shortcodes}
                specialEvent={specialEvent}
              />
            }
          />
          <Route
            path="/:id"
            element={
              <Component
                key={`survey-screen-${screenData?.id}`}
                currentScreenId={screenData?.id}
                name={screenData?.surveyField}
                screenData={screenData?.data[Object.keys(screenData.data)[0]]}
                followUpQuestion={screenData?.data.followUpQuestion}
                headerProps={headerProps}
                footerProps={footerProps}
                shortcodes={screenData?.shortcodes}
                specialEvent={specialEvent}
              />
            }
          />
        </Routes>
      </AudioContext.Provider>
    </ColourContext.Provider>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  survey: state.survey,
  specialEvents:
    state.projects?.find(
      (project) => project.id === state.surveyMetaData?.projectId
    )?.specialEvents ?? null,
  typesCategories:
    (
      (state.user?.id === 'demo'
        ? state?.demoGrogShops
        : state?.grogShops ?? []
      )?.find(
        (shop) => shop.Id === state.surveyMetaData?.shopId
      ) as GrogShopDataModel
    )?.typesCategories ?? null
})

export const SurveyScreen = connect(mapStateToProps)(InitialSurveySetup)
