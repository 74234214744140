import React from 'react'
import { Grid, Paper } from '@mui/material'
import { Border } from 'components/Border/Border.component'
import { BorderType } from 'components/Border/Border.model'
import { IconType } from 'components/Icons/Icon.model'
import { useColourPicker } from 'context/ColourContext'
import { SectionItemProps } from './SectionItem.model'
import { colors } from 'shared/theme/theme'
import classnames from 'classnames'
import styles from './SectionItem.module.scss'
import { Icon } from 'components/Icons/Icon.component'

export const SectionItem: React.FC<SectionItemProps> = React.memo((props) => {
  const { option, noWrap, click, isSelected, handleItemClick } = props

  const { secondaryColour } = useColourPicker()

  /*
   * Get the colour of the button, dependent on if it is clicked or not.
   * By default all options will be white when both clicked and unclicked.
   */
  const backgroundColor =
    click && isSelected
      ? option.fillOnSelect || colors.white
      : option.fill || colors.white

  return (
    <Grid
      item
      lg={3}
      md={3}
      sm={4}
      xs={6}
      display="flex"
      flexDirection="column"
    >
      <div
        className={classnames(styles['section-item'], {
          [styles['section-item--completed']]:
            option.completed || option.disabled,
          [styles['section-item--dimmed']]: click && !isSelected,
          [styles['section-item--disableClick']]: option.disabled
        })}
        onClick={() => handleItemClick(option)}
      >
        <Border type={BorderType.Option1} fill={backgroundColor} dropShadow />
        <Paper className={styles['section-item-card']}>
          {option.completed ? (
            <Icon
              className={styles['section-item-tickIcon']}
              icon={IconType.Tick}
              size={32}
              stroke={colors.white}
              fill={secondaryColour}
              border={false}
            />
          ) : null}
          <div
            className={
              noWrap
                ? styles['section-item-content-nowrap']
                : styles['section-item-content']
            }
          >
            {option.icon && (
              <div className={styles['section-item-icon']}>
                <img src={option.icon} alt={`Image of ${option.label}`} />
              </div>
            )}
            <div className={styles['section-item-label']}>{option.label}</div>
          </div>
        </Paper>
      </div>
    </Grid>
  )
})
