import React, { useState } from 'react'
import type { ISurveyMetaData, SurveyState } from 'store/type'
import { connect, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from './EnterParticipantsSLKScreen.module.scss'
import type { EnterParticipantsSLKScreenComponentProps } from './EnterParticipantsSLKScreen.model'
import { colors } from 'shared/theme/theme'
import { AutoComplete } from 'components/AutoComplete/AutoComplete.component'
import { Button } from 'components/Button/Button.component'
import type { Dispatch } from 'redux'
import {
  updateSurveyAnswer,
  updateSurveyMetadata,
  updateContinueLocalIdentifier,
  updateAnalytics,
  updateUserJourney,
  updateGrogDiaryUserJourney,
  updateGrogDiaryCurrentConsumption,
  updateOutroUserJourney,
  updateGrogDiaryCurrentSelections
} from 'store/reducer'
import { InputErrorMessages, InputFormat } from 'components/Input/Input.model'
import { formatDate } from 'shared/utils/formatTime/formatTime'
import { validateSLK } from 'shared/utils/validateValue/validateValue'
import {
  filterAllSavedSlks,
  getContinuingSurveySlks
} from 'shared/utils/slkUtils/slkUtils'
import { assertNonNull } from 'shared/types/guards'

const EnterParticipantsSLKScreenComponent: React.FC<
  EnterParticipantsSLKScreenComponentProps
> = (props) => {
  const navigate = useNavigate()
  const dispatch: Dispatch<any> = useDispatch()
  const {
    option,
    slkList,
    surveyMetaData,
    surveyResponses,
    surveyScreens,
    isContinueSurvey = false
  } = props
  const [participantSLK, setParticipantSLK] = useState<string>()
  const [inputError, setInputError] = useState<string>()
  const [isParticipantSLKValid, setIsParticipantSLKValid] =
    useState<boolean>(false)
  const [hasFoundExistingSurvey, setHasFoundExistingSurvey] =
    useState<boolean>(false)
  const [nextScreenId, setNextScreenId] = useState<string>()
  const [dateLastCompleted, setDateLastCompleted] = useState<string>()

  let filteredSLKs: string[]

  // If continuing a survey, only include SLKs with a saved survey
  if (isContinueSurvey) {
    filteredSLKs = getContinuingSurveySlks(
      surveyResponses,
      surveyMetaData.projectId
    )
  } else {
    filteredSLKs = filterAllSavedSlks(slkList, surveyResponses, {
      projectId: surveyMetaData.projectId,
      isInProgress: false
    })
  }

  const handleSLKInputChange = (value: string, error?: string) => {
    if (error) {
      setInputError(error)
      setIsParticipantSLKValid(false)
    } else if (!filteredSLKs.includes(value)) {
      setInputError('SLK not found.')
      setIsParticipantSLKValid(false)
    } else {
      setInputError(undefined)
      setParticipantSLK(value)
      setIsParticipantSLKValid(true)
    }
  }

  const handleBackToDashboard = () => {
    navigate('/ra-dashboard/dashboard')
  }

  const validateSLKInput = (value: string) => {
    if (!value) {
      return InputErrorMessages.empty
    }

    if (!validateSLK(value)) {
      return InputErrorMessages.slkFormat
    }

    return undefined
  }

  const handleSubmitParticipantSLK = () => {
    if (!isParticipantSLKValid) return
    const foundSurveyResponse = surveyResponses?.find(
      (response) =>
        response.metadata?.slk?.trim() === participantSLK?.trim() &&
        response.metadata?.flaggedIncomplete &&
        !response.metadata?.flaggedDeleted &&
        response.metadata?.siteId == surveyMetaData?.siteId &&
        response.metadata?.projectId == surveyMetaData?.projectId
    )

    if (!isContinueSurvey) {
      if (foundSurveyResponse) {
        setInputError('SLK already exists, please continue your survey!')
        return
      }

      const updatedMetadata: ISurveyMetaData = {
        ...surveyMetaData,
        slk: participantSLK
      }
      dispatch(updateSurveyMetadata(updatedMetadata))
      dispatch(updateContinueLocalIdentifier(undefined))
      navigate('/survey')
    } else {
      if (!foundSurveyResponse) {
        setInputError('Not found')
        return
      }
      setHasFoundExistingSurvey(true)
      const surveyEndDate = formatDate(foundSurveyResponse.metadata!.endTime)
      setDateLastCompleted(surveyEndDate)
      dispatch(updateSurveyAnswer(foundSurveyResponse.responses))
      dispatch(
        updateUserJourney({
          userJourney: foundSurveyResponse.userJourney,
          continue: true
        })
      )
      dispatch(
        updateGrogDiaryUserJourney({
          actionType: 'continue',
          data: foundSurveyResponse.grogDiaryUserJourney
        })
      )
      dispatch(
        updateGrogDiaryCurrentConsumption({
          actionType: 'continue',
          data: foundSurveyResponse.grogDiaryCurrentState!.consumption
        })
      )
      dispatch(
        updateGrogDiaryCurrentSelections({
          actionType: 'continue',
          data: foundSurveyResponse.grogDiaryCurrentState!.selections
        })
      )
      dispatch(
        updateOutroUserJourney({
          actionType: 'continue',
          data: foundSurveyResponse.outroUserJourney
        })
      )
      dispatch(updateAnalytics(foundSurveyResponse.analytics))
      dispatch(
        updateContinueLocalIdentifier(foundSurveyResponse.localIdentifier)
      )
      dispatch(
        updateSurveyMetadata({
          ...foundSurveyResponse.metadata,
          resumeTime: new Date()
        })
      )
      if (foundSurveyResponse.metadata!.breakPoint) {
        setNextScreenId(foundSurveyResponse.metadata!.breakPoint)
      } else {
        const lastResponseField = Object.keys(
          foundSurveyResponse.responses!
        ).at(-1)
        const lastScreen = surveyScreens?.find(
          (screen) => screen.surveyField === lastResponseField
        )
        setNextScreenId(lastScreen ? lastScreen.id : '')
      }
    }
  }

  return hasFoundExistingSurvey && isContinueSurvey ? (
    <main className={styles.enterparticipantsslkscreen}>
      <h1
        style={{
          visibility: 'hidden',
          position: 'absolute',
          pointerEvents: 'none'
        }}
      >
        Enter Participant SLK
      </h1>
      <div className={styles['enterparticipantsslkscreen-content']}>
        <div
          className={
            styles['enterparticipantsslkscreen-content-foundedtextcontainer']
          }
        >
          <h2
            className={
              styles[
                'enterparticipantsslkscreen-content-foundedtextcontainer-title'
              ]
            }
          >
            You’ve successfully found an existing participant.
          </h2>
          <p>Participant last completed a survey on {dateLastCompleted}</p>
        </div>
        <div className={styles['enterparticipantsslkscreen-content-buttons']}>
          <Button
            variation="secondary"
            width="l"
            onClick={handleBackToDashboard}
          >
            Back
          </Button>
          <Button
            variation="primary"
            width="l"
            onClick={() => navigate(`/survey/${nextScreenId}`)}
          >
            Next
          </Button>
        </div>
      </div>
    </main>
  ) : (
    <div className={styles.enterparticipantsslkscreen}>
      <div className={styles['enterparticipantsslkscreen-content']}>
        <h2>Please enter the participant&apos;s code</h2>
        <div
          className={
            styles['enterparticipantsslkscreen-content-inputcontainer']
          }
        >
          <AutoComplete
            className={
              styles['enterparticipantsslkscreen-content-inputcontainer-input']
            }
            dataList={filteredSLKs}
            inputOption={option}
            setValue={(value, error) => handleSLKInputChange(value, error)}
            validateValue={validateSLKInput}
            style={{ backgroundColor: colors.white }}
          />
          <p
            className={
              styles[
                'enterparticipantsslkscreen-content-inputcontainer-errortext'
              ]
            }
          >
            {inputError}
          </p>
        </div>
        <div className={styles['enterparticipantsslkscreen-content-buttons']}>
          <Button
            variation="secondary"
            width="l"
            onClick={handleBackToDashboard}
          >
            Back
          </Button>
          <Button
            variation="primary"
            width="l"
            onClick={handleSubmitParticipantSLK}
            disabled={!isParticipantSLKValid}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (
  state: SurveyState
): Omit<EnterParticipantsSLKScreenComponentProps, 'isContinueSurvey'> => {
  assertNonNull(state.surveyMetaData, 'state.surveyMetadata')

  return {
    slkList: state.slkList ?? [],
    surveyMetaData: state.surveyMetaData,
    surveyResponses: state.surveyResponses ?? [],
    surveyScreens: state.survey?.screens ?? [],
    option: {
      label: 'Participants SLK',
      value: null,
      required: false,
      allowMultipleLines: false,
      inputFormat: InputFormat.slk
    }
  }
}

export const EnterParticipantsSLKScreen = connect(mapStateToProps)(
  EnterParticipantsSLKScreenComponent
)
