import { createSlice } from '@reduxjs/toolkit'
import {
  fetchAllAppDataAPI,
  fetchSurveyAPI,
  loginAPI,
  syncSurveysAPI
} from './actionCreators'
import type {
  AssetUrls,
  SurveyGrogDiaryCurrentConsumption,
  SurveyGrogDiaryCurrentSelections,
  SurveyGrogDiaryCurrentState,
  SurveyResponse,
  SurveyState
} from './type'
import { v4 as uuidv4 } from 'uuid'
import { GrogDiaryStep } from 'screens/survey/GrogDiaryScreen/GrogDiaryScreen.model'
import type { SyncSurveysAPIResultData } from './actionCreators.model'
import type { DeepMutable } from 'shared/types/general'
import { GROG_DIARY_CURRENT_STATE_DEFAULT } from 'shared/constants/Constants.d'
import { assertNonNull } from 'shared/types/guards'

const initialState: SurveyState = {
  offline: undefined,
  syncRounds: undefined,
  syncStatus: undefined,
  emailSuggestions: [],
  lastLoginStatus: undefined,
  loginAttempt: undefined,
  isDemoMode: false,
  user: undefined,
  service: undefined,
  demoService: undefined,
  geoLocations: undefined,
  demoGeoLocations: undefined,
  grogShops: undefined,
  demoGrogShops: undefined,
  projects: [],
  projectSiteTargets: [],
  selectedProjectSite: undefined,
  survey: undefined,
  surveyData: undefined,
  surveyMetaData: undefined,
  surveyResponses: [],
  assetUrls: undefined,
  demoAssetUrls: undefined,
  continueLocalIdentifier: undefined,
  userJourney: [],
  grogDiaryUserJourney: [{ step: GrogDiaryStep.LASTDAY, skipped: false }],
  grogDiaryCurrentState: structuredClone(
    GROG_DIARY_CURRENT_STATE_DEFAULT
  ) as DeepMutable<SurveyGrogDiaryCurrentState>,
  drinkingSessions: undefined,
  analytics: undefined,
  lastQTime: undefined
}

const tryParseSpecificReturnedSurveyResponse = (
  data: SyncSurveysAPIResultData,
  responseType: keyof SyncSurveysAPIResultData,
  defaultData: SurveyResponse
): SurveyResponse => {
  const item = data[responseType].find(
    (cItem) => cItem.localIdentifier === defaultData.localIdentifier
  )

  return item ? { ...item, local: defaultData.local } : defaultData
}

const slice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    updateUserJourney(state, action) {
      if (action.payload && action.payload.continue) {
        state.userJourney = action.payload.userJourney
      } else if (
        state.userJourney &&
        state.userJourney?.length > 0 &&
        action.payload === -1
      ) {
        state.userJourney.pop()
      } else if (action.payload === undefined) {
        state.userJourney = []
      } else {
        if (!state.userJourney) {
          state.userJourney = []
        }
        state.userJourney.push(action.payload)
      }
    },

    updateGrogDiaryUserJourney(state, action) {
      switch (action.payload.actionType) {
        case 'continue':
          // Restore state
          state.grogDiaryUserJourney = action.payload.data
          break

        case 'push': {
          // Convert to string, then add to array
          state.grogDiaryUserJourney?.push(action.payload.data)
          break
        }

        case 'pop':
          state.grogDiaryUserJourney?.pop()
          break

        case 'mark-skipped': {
          // Marks the current consumption as "skipped"
          const mostRecentStep = state.grogDiaryUserJourney?.pop()
          if (!mostRecentStep) {
            // Do nothing if no step is recorded
            return
          }

          const skippedStepData = {
            step: mostRecentStep.step,
            skipped: true,
            skippedState: action.payload.data
          }

          state.grogDiaryUserJourney?.push(skippedStepData)
          break
        }

        case 'clear-skipped': {
          // Clears the most recent step being marked as being "skipped", or does nothing
          // if it was not marked as being skipped
          const mostRecentStep = state.grogDiaryUserJourney?.pop()
          if (!mostRecentStep || !mostRecentStep.skipped) {
            // Do nothing if no step is recorded OR if the most recent step was NOT marked
            // as skipped
            return
          }

          const regularStepData = {
            step: mostRecentStep.step,
            skipped: false
          } as const

          state.grogDiaryUserJourney?.push(regularStepData)
          break
        }

        case 'rewind-to-date-start': {
          // Loop which will pop ALL consumption steps until we reach either the "people"
          // slider, or the "last day" calendar selection
          // - Initialiser: most recent step of Grog Diary user journey
          // - Check: make sure step is not nullish (out-of-bounds for user journey), or
          //   is the "people" slider/"last day" calendar selection
          // - Increment: get the next last step in the diary
          for (
            let step = state.grogDiaryUserJourney?.at(-1)?.step;
            step != null &&
            step != GrogDiaryStep.PEOPLE &&
            step != GrogDiaryStep.LASTDAY;
            step = state.grogDiaryUserJourney?.at(-1)?.step
          ) {
            // Pop the step from the diary
            state.grogDiaryUserJourney?.pop()
          }
          break
        }

        case 'clear':
          state.grogDiaryUserJourney = [
            { step: GrogDiaryStep.LASTDAY, skipped: false }
          ]
          break
      }
    },

    updateGrogDiaryCurrentConsumption(state, action) {
      assertNonNull(state.grogDiaryCurrentState, 'grogDiaryCurrentState')

      switch (action.payload.actionType) {
        case 'delete':
          state.grogDiaryCurrentState.consumption = undefined
          break

        case 'setValue':
          // Otherwise, we are setting a value
          if (!state.grogDiaryCurrentState.consumption) {
            state.grogDiaryCurrentState.consumption = {}
          }

          // Set with the key directly to the required value
          state.grogDiaryCurrentState.consumption[
            action.payload.key as keyof SurveyGrogDiaryCurrentConsumption
          ] = action.payload.value
          break

        case 'continue':
          state.grogDiaryCurrentState.consumption = action.payload.data
          break
      }
    },

    updateGrogDiaryCurrentSelections(state, action) {
      assertNonNull(state.grogDiaryCurrentState, 'grogDiaryCurrentState')

      switch (action.payload.actionType) {
        case 'setValue': {
          // Use key to select which selection array we are updating
          const selectionKey = action.payload
            .key as keyof SurveyGrogDiaryCurrentSelections

          state.grogDiaryCurrentState.selections[selectionKey] =
            action.payload.value
          break
        }

        case 'clear':
          state.grogDiaryCurrentState.selections = structuredClone(
            GROG_DIARY_CURRENT_STATE_DEFAULT.selections
          ) as DeepMutable<SurveyGrogDiaryCurrentSelections>
          break

        case 'continue':
          state.grogDiaryCurrentState.selections = action.payload.data
          break
      }
    },

    updateOutroUserJourney(state, action) {
      switch (action.payload.actionType) {
        case 'continue':
          // Restore state
          state.outroUserJourney = action.payload.data
          break

        case 'moving-forward': {
          if (!state.outroUserJourney) {
            state.outroUserJourney = []
          }

          // Outro - intro section: the 'currOutroSection' at this stage is null
          if (!action.payload.data.currOutroSection) {
            state.outroUserJourney = [
              {
                outroSection: action.payload.data.nextOutroSection,
                completed: false
              }
            ]
            return
          }

          const arrLength = state.outroUserJourney.length
          for (let i = arrLength - 1; i >= 0; --i) {
            if (
              state.outroUserJourney[i].outroSection.valueOf() !==
              action.payload.data.currOutroSection.valueOf()
            ) {
              // If the lastOutroSectionItem <> the current OutroSection, drop the lastOutroSectionItem.
              // (This scenario would happen when moving backward and forward between Outro sections)
              state.outroUserJourney.pop()
            } else {
              // If the lastOutroSectionItem == the current OutroSection,
              // mark the current OutroSection as completed and push it back to the journey
              state.outroUserJourney.pop()
              state.outroUserJourney?.push({
                outroSection: action.payload.data.currOutroSection,
                completed: true
              })
              break
            }
          }

          // Push the next OutroSection with an incomplete status to the journey
          if (action.payload.data.nextOutroSection) {
            state.outroUserJourney?.push({
              outroSection: action.payload.data.nextOutroSection,
              completed: false
            })
          }
          break
        }

        case 'moving-backward': {
          if (!state.outroUserJourney || state.outroUserJourney.length === 0) {
            return
          }

          // Outro - intro section: the 'currOutroSection' at this stage is null
          // Outro - intro section is not included in the Outro journey.
          if (!action.payload.data.currOutroSection) {
            state.outroUserJourney = []
            return
          }

          const arrLength = state.outroUserJourney.length
          for (let i = arrLength - 1; i >= 0; --i) {
            if (
              state.outroUserJourney[i].outroSection.valueOf() !==
              action.payload.data.currOutroSection.valueOf()
            ) {
              // If the lastOutroSectionItem <> the current OutroSection, drop the lastOutroSectionItem
              // (This scenario would happen when moving backward and forward between Outro sections)
              state.outroUserJourney.pop()
            } else {
              // If the lastOutroSectionItem == the current OutroSection,
              // mark the current OutroSection as incomplete and push it back to the journey
              state.outroUserJourney.pop()
              state.outroUserJourney?.push({
                outroSection: action.payload.data.currOutroSection,
                completed: false
              })
              break
            }
          }
          break
        }

        case 'clear':
          state.outroUserJourney = undefined
          break
      }
    },

    updateService(state, action) {
      state.service = action.payload
    },

    updateUser(state, action) {
      state.user = action.payload
    },

    updateProjects(state, action) {
      state.projects = action.payload
    },

    updateSelectedProjectSite(state, action) {
      state.selectedProjectSite = action.payload
    },

    updateSurvey(state, action) {
      state.survey = action.payload
    },

    updateDrinkingSession(state, action) {
      state.drinkingSessions = action.payload
    },

    logout(state) {
      state.offline = undefined
      state.lastLoginStatus = undefined
      state.loginAttempt = undefined
      state.isDemoMode = false
      state.user = undefined
      state.projects = []
      state.projectSiteTargets = []
      state.selectedProjectSite = undefined
      state.survey = undefined
      state.surveyData = undefined
      state.surveyMetaData = undefined
      state.userJourney = []
      state.grogDiaryUserJourney = [
        { step: GrogDiaryStep.LASTDAY, skipped: false }
      ]
      state.grogDiaryCurrentState = structuredClone(
        GROG_DIARY_CURRENT_STATE_DEFAULT
      ) as DeepMutable<SurveyGrogDiaryCurrentState>
      state.analytics = undefined
      state.lastQTime = undefined
      state.continueLocalIdentifier = undefined
    },

    updateSurveyMetadata(state, action) {
      state.surveyMetaData = {
        ...state.surveyMetaData,
        ...action.payload
      }
    },

    updateSurveyAnswer(state, action) {
      state.surveyData = {
        ...state.surveyData,
        ...action.payload
      }
    },

    updateContinueLocalIdentifier(state, action) {
      state.continueLocalIdentifier = action.payload
    },

    updateContinueSurveyResponse(state, action) {
      const matchingResponses = state.surveyResponses?.filter(
        (response) =>
          state.continueLocalIdentifier &&
          response.localIdentifier === state.continueLocalIdentifier
      )

      if (matchingResponses && matchingResponses.length > 0) {
        const updatedResponse = { ...matchingResponses[0] }
        if (updatedResponse?.metadata) {
          updatedResponse.metadata = {
            ...action.payload.metadata,
            slk: updatedResponse.metadata.slk
          }
          updatedResponse.responses = { ...action.payload.responses }
          updatedResponse.analytics = action.payload.analytics
          updatedResponse.userJourney = action.payload.userJourney
          updatedResponse.outroUserJourney = action.payload.outroUserJourney
          updatedResponse.grogDiaryUserJourney =
            action.payload.grogDiaryUserJourney
          updatedResponse.grogDiaryCurrentState =
            action.payload.grogDiaryCurrentState
          updatedResponse.drinkingSessions = action.payload.drinkingSessions
          updatedResponse.local = true

          state.surveyResponses = [
            ...(state.surveyResponses || []).filter(
              (each) => each.localIdentifier != state.continueLocalIdentifier
            )
          ]
          state.surveyResponses.push(updatedResponse)
        }
      }

      state.surveyData = undefined
      state.userJourney = []
      state.grogDiaryUserJourney = [
        { step: GrogDiaryStep.LASTDAY, skipped: false }
      ]
      state.analytics = undefined
      state.lastQTime = undefined
    },

    addCompletedSurvey(state, action) {
      if (action.payload.save) {
        state.surveyResponses = [
          ...(state.surveyResponses || []),
          {
            id: action.payload.id,
            localIdentifier: uuidv4(),
            local: true,
            metadata: action.payload.metadata,
            responses: action.payload.responses,
            userJourney: action.payload.userJourney,
            grogDiaryUserJourney: action.payload.grogDiaryUserJourney,
            grogDiaryCurrentState: action.payload.grogDiaryCurrentState,
            outroUserJourney: action.payload.outroUserJourney,
            analytics: action.payload.analytics,
            drinkingSessions: action.payload.drinkingSessions
          }
        ]
      }

      state.surveyMetaData = {
        ...state.surveyMetaData,
        startTime: undefined
      }
      state.surveyData = undefined
      state.userJourney = []
      state.grogDiaryUserJourney = [
        { step: GrogDiaryStep.LASTDAY, skipped: false }
      ]
      state.grogDiaryCurrentState = structuredClone(
        GROG_DIARY_CURRENT_STATE_DEFAULT
      ) as DeepMutable<SurveyGrogDiaryCurrentState>
      state.analytics = undefined
      state.lastQTime = undefined
    },

    updateEmailSuggestion(state, action) {
      const newEmail = action.payload.email.toLowerCase()

      // Add the new or moved email to the start of the list so
      // emails appear in the order they were used
      state.emailSuggestions = [
        newEmail,
        ...(state.emailSuggestions?.filter((email) => email !== newEmail) || [])
      ]
    },

    updateValidatedSLK(state, action) {
      const matchingResponses = state.surveyResponses?.filter(
        (response) => response.localIdentifier == action.payload.localIdentifier
      )

      if (matchingResponses && matchingResponses.length > 0) {
        const updatedResponse = { ...matchingResponses[0] }
        updatedResponse.metadata!.slk = action.payload.slk
        updatedResponse.metadata!.status = updatedResponse.metadata!
          .flaggedDeleted
          ? 'deletion'
          : updatedResponse.metadata!.flaggedIncomplete
          ? 'incomplete'
          : 'complete'

        state.surveyResponses = [
          ...(state.surveyResponses || []).filter(
            (response) =>
              response.localIdentifier != action.payload.localIdentifier
          )
        ]
        state.surveyResponses?.push(updatedResponse)
      }
    },

    updateLastQTime(state, action) {
      state.lastQTime = action.payload
    },

    updateAnalytics(state, action) {
      if (state.analytics) {
        const existingIndex = state.analytics.findIndex(
          (item) => item.question === action.payload.question
        )

        // Check whether question already exists in analytics state
        if (existingIndex > -1) {
          state.analytics[existingIndex] = action.payload
        } else {
          Array.isArray(action.payload)
            ? (state.analytics = [...action.payload])
            : state.analytics.push(action.payload)
        }
      } else {
        if (action.payload == null) {
          // Handle case where payload may be nullish (edge case identified in issue
          // #588), perhaps when there is no analytics to update since the first screen
          // was never navigated away from
          state.analytics = []
        } else if (Array.isArray(action.payload)) {
          state.analytics = [...action.payload]
        } else {
          state.analytics = [action.payload]
        }
      }
    },

    updateLastLoginStatus(state, action) {
      state.lastLoginStatus = action.payload
    }
  },

  extraReducers: (builder) => {
    builder.addCase(loginAPI.fulfilled, (state, action) => {
      state.offline = false
      state.lastLoginStatus = {
        loggedIn: true,
        serviceId: action.payload.userInfo.serviceId
      }
      state.loginAttempt = undefined
      state.user = action.payload.userInfo
      state.isDemoMode = action.payload.demoMode ?? false
    })

    builder.addCase(loginAPI.rejected, (state, action) => {
      state.lastLoginStatus = { loggedIn: false }

      if (action.payload) {
        state.offline = false
        state.lastLoginStatus.errorMessage = action.payload as string
      } else {
        // An unexpected error has occurred
        state.offline = true
      }

      state.loginAttempt =
        (state.loginAttempt != null ? state.loginAttempt : 0) + 1
    })

    builder.addCase(fetchAllAppDataAPI.fulfilled, (state, action) => {
      const draftAssetsInfo = action.payload
        .assetsInfo as DeepMutable<AssetUrls>

      if (state.isDemoMode) {
        state.demoService = action.payload.data
        state.demoGeoLocations = action.payload.locations
        state.demoGrogShops = action.payload.grogshops
        state.demoAssetUrls = draftAssetsInfo
      } else {
        state.service = action.payload.data
        state.geoLocations = action.payload.locations
        state.grogShops = action.payload.grogshops
        state.slkList = action.payload.slks
        state.assetUrls = draftAssetsInfo
      }
    })

    builder.addCase(fetchAllAppDataAPI.rejected, (state) => {
      state.offline = true
      state.loginAttempt = undefined
    })

    builder.addCase(fetchSurveyAPI.fulfilled, (state, action) => {
      state.service = action.payload.data
    })

    builder.addCase(syncSurveysAPI.fulfilled, (state, action) => {
      if (!action.payload?.data) return

      const completedIdentifiers = new Set(
        action.payload.data.completedSubmissions.map(
          (item) => item.localIdentifier
        )
      )
      const incompleteIdentifiers = new Set(
        action.payload.data.toBeContinueSubmissions.map(
          (item) => item.localIdentifier
        )
      )

      const updatedLocalResponses = (state.surveyResponses || []).map(
        (response) => {
          if (completedIdentifiers.has(response.localIdentifier)) {
            return tryParseSpecificReturnedSurveyResponse(
              action.payload.data,
              'completedSubmissions',
              response
            )
          }

          if (
            incompleteIdentifiers.has(response.localIdentifier) &&
            response.local
          ) {
            return tryParseSpecificReturnedSurveyResponse(
              action.payload.data,
              'toBeContinueSubmissions',
              response
            )
          }

          return response
        }
      )

      const localIdentifiers = new Set(
        updatedLocalResponses.map((response) => response.localIdentifier)
      )

      state.surveyResponses = [
        ...updatedLocalResponses,
        ...action.payload.data.toBeContinueSubmissions.filter(
          (item) => !localIdentifiers.has(item.localIdentifier)
        )
      ]

      if (action.payload.projectSiteTargets) {
        state.projectSiteTargets = action.payload.projectSiteTargets
      }

      state.syncRounds = (state.syncRounds != null ? state.syncRounds : 0) + 1
      state.syncStatus = { isSynced: true }
    })

    builder.addCase(syncSurveysAPI.rejected, (state, action) => {
      state.syncRounds = (state.syncRounds != null ? state.syncRounds : 0) + 1
      state.syncStatus = { isSynced: false }
      if (action.payload) {
        state.syncStatus.errorMessage = action.payload as string
      }
    })
  }
})

export const {
  updateUserJourney,
  updateGrogDiaryUserJourney,
  updateGrogDiaryCurrentConsumption,
  updateGrogDiaryCurrentSelections,
  updateOutroUserJourney,
  updateService,
  updateUser,
  updateProjects,
  updateSelectedProjectSite,
  updateSurvey,
  updateDrinkingSession,
  logout,
  updateSurveyAnswer,
  updateSurveyMetadata,
  updateContinueLocalIdentifier,
  addCompletedSurvey,
  updateEmailSuggestion,
  updateContinueSurveyResponse,
  updateValidatedSLK,
  updateLastQTime,
  updateAnalytics,
  updateLastLoginStatus
} = slice.actions

export default slice.reducer
