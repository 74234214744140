import type React from 'react'
import type { SurveyState } from 'store/type'

/**
 * Enum to track the state of fetching the survey manifest from Umbraco in the
 * `LoginScreen` component.
 */
export enum FetchingManifestState {
  NOT_STARTED,
  STARTED,
  FINISHED
}

/**
 * Enum to track the state of narrowing down projects by RA in the `LoginScreen`
 * component.
 */
export enum NarrowingProjectsState {
  NOT_STARTED,
  STARTED,
  FINISHED
}

/**
 * An interface describing references to React `setState` functions to handle the current
 * loading status of the login screen, as well as any alert text.
 *
 * The functions vary depending on whether the login screen loading process was triggered
 * as a result of a normal login, or by entering demo mode.
 */
export interface LoadingActions {
  /**
   * The React `setState` function used to set a Boolean state variable controlling
   * whether the login screen is currently loading or not.
   */
  setLoading: React.Dispatch<React.SetStateAction<boolean>>

  /**
   * The React `setState` function used to set a string state variable controlling some
   * sort of alert text in case an alert should be displayed to the user on the login
   * screen, allowing for `undefined` values to hide the alert as well.
   */
  setAlertText: React.Dispatch<React.SetStateAction<string | undefined>>
}

export type LoginScreenComponentProps = Partial<SurveyState>
